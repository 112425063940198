// src/components/Authentication/LoginForm.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import mainLogo from "../../assests/mainLogoLogin.png";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const adminCredentials = {
    username: "admin",
    password: "admin@123",
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate credentials
    if (
      username === adminCredentials.username &&
      password === adminCredentials.password
    ) {
      const userInfo = {
        username,
        token: "This is admin token",
        isAdmin: true,
        lastActive: Date.now(),
      };
      login(userInfo);
      navigate("/");
    } else {
      alert("Invalid username or password");
    }

    setUsername("");
    setPassword("");
  };

  // Function to be called when the component is mounted
  const handleMount = () => {
    // window.location.reload();

    localStorage.removeItem("userInfo");
    // console.log("LoginForm component mounted!");

  };

  useEffect(() => {
    handleMount();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
        boxSizing: "border-box",
        width: "100vw",
        textAlign: "center",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          width: "400px",
          height: "380px",
          padding: "40px",
          // border: "1px solid #ccc",
          border: "none",
          borderRadius: "5px",
          boxShadow: "0px 3px 4px 0px rgba(1,1,1,0.2)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // backgroundColor:"rgb(250, 250, 232)"
        
        }}
      >
        <img src={mainLogo} alt="mainLogo" style={{
          width: "55%",
          height: "auto",
          margin: "0px",
          padding: "0px",
          objectFit: "cover",
        }} />
        <p style={{
          fontSize:"0.8rem",
          marginTop:"1rem",
          letterSpacing:"0.8px",
          fontWeight:"500"
        }}>Welcome to admin panel.</p>
        {/* <label style={{ display: "block", marginBottom: "10px" }}>
          Username: */}
        <input
          type="text"
          placeholder="Name"
          value={username}
          onChange={handleUsernameChange}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            // border: "none",
            // borderBottom: "1px solid #ccc",
            // outline:"none",
            marginBottom: "20px",
            fontSize:"1rem"
            
          }}
          // onFocus={(e) => (e.target.style.borderBottom = "1px solid #000")}
          // onBlur={(e) => (e.target.style.borderBottom = "1px solid #ccc")}
        />
        {/* </label> */}
        {/* <label style={{ display: "block", marginBottom: "10px" }}>
          Password: */}
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            // border: "none",
            // borderBottom: "1px solid #ccc",
            // outline:"none",
            // marginBottom: "20px",
            fontSize:"1rem"
            
          }}
          // onFocus={(e) => (e.target.style.borderBottom = "1px solid #000")}
          // onBlur={(e) => (e.target.style.borderBottom = "1px solid #ccc")}
        />
        {/* </label> */}
        <button
          type="submit"
          style={{
            width:"100%",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop:"30px",
            fontSize:"0.9rem",
            fontWeight:"600"
          }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
