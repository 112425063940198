import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../api/api";
import { FaEdit } from "react-icons/fa";
import { MdDetails } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";

const ServiceProvider = () => {
  const [data, setData] = useState([]);
  // console.log("subusers data::", data);
  
  const garageData = data.filter(
    (item) => item.position === "garage" || "self"
  );
  // console.log("garageData:", garageData);


  // for pagination part
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(6);
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current page from URL or default to 1
  const page = parseInt(searchParams.get("page")) || 1;


  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/garage/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/garage/edit/${_id}`);
  const AllotOrder = (_id) => navigate(`/garage/allot/${_id}`);

  const AllotedOrders = (_id) => navigate(`/garage/allotedorders/${_id}`);

  const Removefunction = (_id) => {
    if (window.confirm("Do you want to remove ?")) {
      fetch(`https://gmi-server-1.onrender.com/api/subusers/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          alert("Removed successfully.");
          window.location.reload();
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    // for pagination pass page and limit to get response according to it.
    fetch(`${apiUrl}/api/subusers?page=${page}&limit=${limit}`)
      .then((res) => res.json())
      .then((resp) => {
        setData(resp.subusers)
        setTotalPages(resp.totalSubUsers)
      })
      .catch((err) => console.log(err.message));
  }, [page]); // Only re-fetch when `page` changes



  // pagination btn handler
  const handlePageChange = (pageNumber) => {
    setSearchParams({ page: pageNumber });
  };

  // pagination page number display
  const renderPagination = () => {
    const start = Math.max(1, page - 2);
    const end = Math.min(totalPages, page + 2);
    const paginationItems = [];

    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item border-0 ${page === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
          style={{ cursor: 'pointer' }}
        >
          <span className={`page-link border-1 border-dark ${page === i ? "text-light" : "text-dark"}`}>{i}</span>
        </li>
      )
    };
    return paginationItems;
  }


  return (
    <div>
      <div className="container-fluid m-0">
        <div className="card overflow-auto"
          style={{
            height: "92vh"
          }}
        >
          <div className="card-title">
            <h2 className="m-0 ps-3">Service Provider's List</h2>
          </div>
          <div className="card-body">
            <div className="divbtn">
              <button
                onClick={() => navigate("/subuser/create")}
                className="btn btn-success m-0 mb-4 me-2"
              >
                {/* {"Add New (+)"} */}
                {"Add New Vendor"}
              </button>

              <button
                onClick={() => navigate("/alljobcarts")}
                className="btn btn-primary m-0 mb-4"
              >
                {"All Job Carts"}
              </button>
            </div>
            <table className="container-fluid">
              <thead
                // className="bg-dark text-white"
                style={{ padding: "10px", background: "#FEC324", textTransform: "uppercase", fontWeight: "bold" }}
                claspan=""
              >
                <tr style={{ textAlign: "center" }}>
                  <th style={{ border: "1px solid black" }}>Sr No.</th>
                  <th style={{ border: "1px solid black" }}>title</th>
                  <th style={{ border: "1px solid black" }}>Name</th>
                  <th style={{ border: "1px solid black" }}>Phone</th>
                  <th style={{ border: "1px solid black" }}>Status</th>
                  <th style={{ border: "1px solid black" }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: "1px solid black" }}>
                {data.map((item, index) => (
                  <tr key={item._id}>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>
                      {/* {item._id} */}
                      {index + 1 + (page - 1) * limit} {/* Updated index calculation */}
                    </td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>
                      {item.title}
                    </td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>
                      {item.first_name} {item.last_name}
                    </td>
                    <td style={{ border: "1px solid black", padding: "2px", textAlign: "center" }}>
                      {item.phone}
                    </td>
                    <td
                      style={{
                        // display: "flex",
                        // justifyContent: "space-around",
                        alignItems: "center",
                        textAlign: "center",
                        // margin: "auto",
                        // marginTop: "20%",
                        // width: "20px",
                        // height: "20px",
                        border: "1px solid black",
                      }}
                    >
                      {item.status && item.status === true ? (
                        <i
                          className="fa fa-circle"
                          style={{ color: "green" }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      )}
                    </td>

                    <td style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center", // Align items horizontally in the center
                        gap: "5px", // Space between the buttons
                      }}>

                        <button
                          onClick={() => LoadEdit(item._id)}
                          className="btn btn-success"
                          style={{ margin: "1px" }}
                        >
                          <FaEdit />
                        </button>
                        <button
                          style={{ margin: "1px" }}
                          onClick={() => Removefunction(item._id)}
                          className="btn btn-danger"
                        >
                          <RiDeleteBinLine />
                        </button>
                        <button
                          style={{ margin: "1px" }}
                          onClick={() => LoadDetail(item._id)}
                          className="btn btn-primary"
                        >
                          <AiOutlineOrderedList />
                        </button>
                        <button
                          style={{
                            margin: "1px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px", // spacing between the icon and text
                          }}
                          onClick={() => AllotOrder(item._id)}
                          className="btn btn-warning"
                        >
                          <MdDetails />
                          Allot Ord
                        </button>

                        <button
                          style={{
                            margin: "1px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px", // spacing between the icon and text
                            backgroundColor: "rgb(245, 7, 170",
                            color: "white",
                          }}
                          onClick={() => AllotedOrders(item._id)}
                          className="btn"
                        >
                          <BsCheckCircle />
                          Alloted Ords
                        </button>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Section */}
          <nav aria-label="Page navigation customer" className="mt-3">
            <ul className="pagination  justify-content-center">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link text-dark border-dark"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >&laquo;</button>
              </li>
              {renderPagination()}
              <li className="page-item">
                <button
                  className="page-link text-dark border-dark"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                >&raquo;</button>
              </li>
            </ul>
          </nav>

        </div>
      </div>
    </div>
  );
};

export default ServiceProvider;
