import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../api/api";
import axios from "axios";


const OrdCreate = () => {
  const navigate = useNavigate();

  const [toggleForm, setToggleForm] = useState(false); // toggle between add vehicle and create order form
  // console.log(toggleForm);

  const [inputs, setInputs] = useState({
    customerName: "",
    productName: "",
    status: "",
    // description: "",
    // final_total: 0,
    image: "",
    city: "",
    category: "",
    vehicleNumber: "",
    confirmVehicleNumber: false,
    vehicleMakeModel: "",
    confirmVehicleMakeModel: false,
    vehicleVariant: "",
    confirmVehicleBreakDown: false,
    confirmVehicleInsurance: false,
    vehicleInsuranceStatus: "",
    bikeCC: "",
    carType: "",
    phone: "",
    alternatePhone: "",
    email: "",
    location: "",
    state: "Maharashtra",
    pincode: "",
    servicingType: "",
    total: 0,
    confirmFinalTotal: false, // only for frontend side to get confirmation for total value
    finalTotal: 0,
    dateOfService: "",
    timeSlotOfService: "",
    expectedDeliveryDate: "",
    note: "",
  })

  console.log("Inputs", inputs);

  const [addVehicleData, setAddVehicleData] = useState({  // to add new vehicle in selected customer data
    vehicle_type: "",
    make_model: "",
    variant: "",
    fuel_type: "",
    bike_cc: 0,
    car_type: "",
    vehicle_image: "",
    reg_number: "",
  })

  // console.log("Add vehicle:::", addVehicleData);

  const [recommendations, setRecommendations] = useState({
    customerName: [],
    vehicleMakeModel: [],
    vehicleVariant: [],
    carType: [],
  })

  const [vehicle, setVehicle] = useState([]);
  // console.log("Vehicle::", vehicle);

  const [vehicleMakeModel, setVehicleMakeModel] = useState([])
  // console.log("Vehicle Make Model::", vehicleMakeModel);

  const [plan, setPlan] = useState([]);
  // console.log("plan:", plan);

  const [productNames, setProductNames] = useState([]);
  // console.log("product names:", productNames);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState([]);
  // console.log("Selected Plan:", selectedPlan);

  const [customerData, setCustomerData] = useState([]);
  // console.log("Customer Data:::", customerData);


  const [selectedCustomerData, setSelectedCustomerData] = useState([]); // When user added customer name in name field input in create an order form then it will get all associated data which will auto fillup in all other fields

  // console.log("selected customer ID:::", selectedCustomerData?._id);
  // console.log("selected customer data:::", selectedCustomerData);

  const [getSelectedVehicleData, setGetSelectedVehicleData] = useState([]); // Use in add new vehicle page based on make and model get whole data

  // console.log("selected make and model vehicle data:::", getSelectedVehicleData);

  const [selectedCustomerVehicleData, setSelectedCustomerVehicleData] = useState([]); // Use in create order to select vehicle card that is already added

  // console.log("selected customer vehicle data:::", selectedCustomerVehicleData);

  const [customerNames, setCustomerNames] = useState([]);
  // console.log("Customer Names:::", customerNames);


  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");


  const getTotalOfSelectedPlan = () => {
    // console.log("getTotalOfSelectedPlan");

    if (inputs.city) {
      const cityKey = inputs.city.toLowerCase();
      const cityData = selectedPlan?.[cityKey]?.[0]
      // console.log(`${inputs.city} City:::`, cityData);

      if (inputs.category) {
        const vehicleCategoryKey = inputs.category.toLowerCase();
        console.log(`${inputs.category} category:::`, vehicleCategoryKey);

        if (vehicleCategoryKey === "bike") {
          let selectedTotalData;

          const segmentDate = cityData.bike_segment
          // console.log(`${inputs.city} city bike segment data:::`, segmentDate);

          if (inputs.bikeCC >= 90 && inputs.bikeCC <= 149) {
            selectedTotalData = segmentDate[0]
            // console.log("150cc", segmentDate[0]);
          } else if (inputs.bikeCC >= 150 && inputs.bikeCC <= 200) {
            selectedTotalData = segmentDate[1]
            // console.log("150cc", segmentDate[0]);
          } else if (inputs.bikeCC >= 200) {
            selectedTotalData = segmentDate[2]
            // console.log("150cc", segmentDate[0]);
          }


          if (selectedTotalData) {
            // console.log("Selected Total Data", selectedTotalData);
            const keys = Object.keys(selectedTotalData);
            const value = keys.length > 0 ? selectedTotalData[keys[0]] : undefined;
            // console.log("Total Value", value);

            setInputs((prev) => ({
              ...prev,
              total: value,
              finalTotal: value,
            }))
          }
        } else if (vehicleCategoryKey === "bike yearly") {
          let selectedTotalData;

          const segmentDate = cityData.bike_segment
          // console.log(`${inputs.city} city bike segment data:::`, segmentDate);

          if (inputs.bikeCC >= 90 && inputs.bikeCC <= 149) {
            selectedTotalData = segmentDate[0]
            // console.log("150cc", segmentDate[0]);
          } else if (inputs.bikeCC >= 150 && inputs.bikeCC <= 200) {
            selectedTotalData = segmentDate[1]
            // console.log("150cc", segmentDate[0]);
          } else if (inputs.bikeCC >= 200) {
            selectedTotalData = segmentDate[2]
            // console.log("150cc", segmentDate[0]);
          }


          if (selectedTotalData) {
            // console.log("Selected Total Data", selectedTotalData);
            const keys = Object.keys(selectedTotalData);
            const value = keys.length > 0 ? selectedTotalData[keys[0]] : undefined;
            // console.log("Total Value", value);

            setInputs((prev) => ({
              ...prev,
              total: value,
              finalTotal: value,
            }))
          }
        } else if (vehicleCategoryKey === "car") {
          let selectedTotalData;

          const segmentDate = cityData.car_segment
          // console.log(`${inputs.city} city car segment data:::`, segmentDate);

          if (inputs.carType === "Hatchback") {
            selectedTotalData = segmentDate[0]
            // console.log("Hatchback", segmentDate[0]);
          } else if (inputs.carType === "Sedan") {
            selectedTotalData = segmentDate[1]
            // console.log("Sedan", segmentDate[0]);
          } else if (inputs.carType === "SUV") {
            selectedTotalData = segmentDate[2]
            // console.log("SUV", segmentDate[0]);
          }


          if (selectedTotalData) {
            // console.log("Selected Total Data", selectedTotalData);
            const keys = Object.keys(selectedTotalData);
            const value = keys.length > 0 ? selectedTotalData[keys[0]] : undefined;
            // console.log("Total Value", value);

            setInputs((prev) => ({
              ...prev,
              total: value,
              finalTotal: value,
            }))
          }
        }
      }
    }

  }


  const handleToggleAddVehicle = () => {
    setToggleForm((prev) => !prev)
  }


  // const convertTo24Hour = (time12h) => {
  //   const [time, modifier] = time12h.split(" ");
  //   let [hours, minutes] = time.split(":");

  //   if (modifier === "PM" && hours !== "12") {
  //     hours = parseInt(hours, 10) + 12
  //   }
  //   if (modifier === "AM" && hours === "12") {
  //     hours = "00"
  //   }

  //   return `${hours}:${minutes}`;
  // }


  const timeSlots = [
    "10:00 AM - 12:00 PM",
    "12:00 PM - 02:00 PM",
    "02:00 PM - 04:00 PM",
    "04:00 PM - 06:00 PM",
    "06:00 PM - 08:30 PM",
  ];


  const handleGetVehicleData = async () => {
    try {

      const response = await axios.get(`${apiUrl}/api/vehicles/allvehicles`);

      setVehicle(response.data);

      const vehicleMakeModels = Array.from(
        new Set(
          response.data.map(data => `${data.make_model}|${data.variant}`)
        )).map((item) => {
          const [make_model, variant] = item.split("|");
          return { make_model, variant }
        });

      setVehicleMakeModel(vehicleMakeModels);

    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  }


  const fetchCustomersData = async () => {
    try {

      const response = await axios.get(`${apiUrl}/api/customer/allcustomers?all=true`);

      setCustomerData(response.data.customers);

      const customerNames = response.data.customers.map(data => data.name.toLowerCase());
      setCustomerNames(customerNames);

    } catch (err) {
      console.log("Error in fetching customer data in create order", err.message);

    }
  }


  const fetchProductData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/products/allproducts`
      );
      setPlan(response.data);

      // Create unique combinations of product_name and category
      const uniqueOptions = Array.from(
        new Set(
          response.data.map(
            (data) => `${data.product_name.toLowerCase()}|${data.category.toLowerCase()}`
          )
        )
      ).map((item) => {
        const [product_name, category] = item.split("|");
        return { product_name, category };
      });

      setProductNames(uniqueOptions);
    } catch (err) {
      console.error("Error in fetching product data:", err.message);
    }
    setIsLoading(false);
  };




  const getMatchingPlan = () => {
    const { category, servicingType } = inputs;
    // console.log("category:::", category.toLowerCase());
    // console.log("servicing type:::", servicingType.toLowerCase());

    if (!category || !servicingType) {
      return null;
    }

    const categoryLower = category.toLowerCase();
    const yearlyBasedCategory = `${categoryLower} yearly`;

    const matchingPlan = plan.find((p) => (p.category.toLowerCase() === categoryLower || p.category.toLowerCase() === yearlyBasedCategory) && p.product_name.toLowerCase().includes(servicingType.toLowerCase()));

    return matchingPlan;
  }


  useEffect(() => {
    handleGetVehicleData()
    fetchProductData()
    fetchCustomersData()

    const today = new Date();
    const next15Days = new Date();
    next15Days.setDate(today.getDate() + 15);

    // Format dates to YYYY-MM-DD
    const formatDate = (date) => date.toISOString().split("T")[0];

    setMinDate(formatDate(today));
    setMaxDate(formatDate(next15Days));
  }, []);


  useEffect(() => {
    setAddVehicleData({
      vehicle_type: getSelectedVehicleData.vehicle_type,
      make_model: getSelectedVehicleData.make_model,
      variant: getSelectedVehicleData.variant,
      fuel_type: getSelectedVehicleData.fuel_type,
      bike_cc: getSelectedVehicleData.bike_cc,
      car_type: getSelectedVehicleData.car_type,
      vehicle_image: getSelectedVehicleData.vehicle_image,
      reg_number: inputs.vehicleNumber,
    })
  }, [inputs.vehicleNumber, inputs.vehicleMakeModel])

  useEffect(() => {
    const selectedPlan = getMatchingPlan();
    setSelectedPlan(selectedPlan);

  }, [inputs.category, inputs.servicingType]);

  useEffect(() => {

    if (selectedPlan) {

      setInputs((prev) => ({
        ...prev,
        productName: selectedPlan,
        status: selectedPlan.status,
        // description: selectedPlan.description,
        total: selectedPlan.total,
        // finalTotal: selectedPlan.total,
      }))

      getTotalOfSelectedPlan();
    }
  }, [selectedPlan, inputs.city]);


  useEffect(() => {
    if (inputs.confirmFinalTotal === true) {
      setInputs((prev) => ({
        ...prev,
        finalTotal: inputs.total || 0,
      }))
    }
  }, [inputs.confirmFinalTotal])


  // Check further to optimised it. As in handleInputChange if suppose it can be handle then add in handleInputChange
  useEffect(() => {
    if (selectedCustomerData) {
      setInputs((prev) => ({
        ...prev,
        phone: selectedCustomerData?.phone_no || "",
        email: selectedCustomerData?.email || "",
      }));
      setSelectedCustomerVehicleData(selectedCustomerData?.vehicles || []);
    } else {
      setInputs((prev) => ({
        ...prev,
        phone: "",
        email: "",
        category: "",
        vehicleNumber: "",
        vehicleMakeModel: "",
        vehicleVariant: "",
        bikeCC: "",
        carType: "",
        total: 0,
        servicingType: "",
      }));
      setSelectedCustomerVehicleData([]);
    }
  }, [selectedCustomerData]);



  const handleToggleCheckBoxInputFields = (key, message) => {
    if (window.confirm(message)) {
      setInputs((prev) => {
        const updatedState = {
          ...prev,
          [key]: !prev[key],
        }

        // Reset `vehicleInsuranceStatus` only if one of the specified conditions is false
        if (!updatedState.confirmVehicleMakeModel || !updatedState.confirmVehicleNumber) {
          updatedState.vehicleInsuranceStatus = ""; // Reset radio buttons
        }

        return updatedState;

      });
    }
  };

  const handleInsuranceStatusRadioChange = (status, message) => {
    if (inputs.confirmVehicleMakeModel === false || inputs.confirmVehicleNumber === false) {
      window.alert("First confirm vehicle make model and registration number check box!")
    } else if (window.confirm(message)) {
      setInputs((prev) => ({
        ...prev,
        vehicleInsuranceStatus: status,
      }));
    }
  };

  const handleInputChange = (field, value) => {

    if (field === "customerName") {
      const selectedCustomer = customerData.find((customer) => customer.name.toLowerCase() === value.toLowerCase())
      setSelectedCustomerData(selectedCustomer || null)
      // setSelectedCustomerVehicleData(selectedCustomer?.vehicles || [])

      // if (selectedCustomer) {
      //   setInputs((prev) => ({
      //     ...prev,
      //     phone: selectedCustomer?.phone_no,
      //     email: selectedCustomer?.email
      //   }))
      // } else {
      //   setInputs((prev) => ({
      //     ...prev,
      //     phone: "",
      //     email: ""
      //   }))

      // }
    }

    if (field === "vehicleMakeModel") {
      const [makeModel, variant] = value.split(" - ");
      const getVehicleData = vehicle.find((vehicle) => vehicle.make_model === makeModel && vehicle.variant === variant);
      setGetSelectedVehicleData(getVehicleData || [])
    }
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (value.length >= 1) {
      if (field === "vehicleMakeModel") {
        const filtered = vehicleMakeModel.filter((vehicle) => vehicle.make_model.toLowerCase().includes(value.toLowerCase()));
        setRecommendations((prev) => ({
          ...prev,
          [field]: filtered,
        }));
      } else if (field === "customerName") {
        const filtered = customerNames.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
        setRecommendations((prev) => ({
          ...prev,
          [field]: filtered,
        }));
      } else {
        setRecommendations((prev) => ({
          ...prev,
          [field]: [],
        }));
      }
      return;
    }
  }


  const handleAddVehicle = async (e) => {
    e.preventDefault();

    if (inputs.customerName.length === 0) {
      alert("Please fill Customer Name.");
      // setIsLoading(false);
      return;
    } else if (inputs.vehicleNumber.length === 0) {
      alert("Please fill Registration Number.");
      // setIsLoading(false);
      return;
    } else if (inputs.vehicleMakeModel.length === 0) {
      alert("Please Select Vehicle Make & Model");
      // setIsLoading(false);
      return;
    }

    try {
      await axios.put(
        `${apiUrl}/api/customer/addvehicle/${selectedCustomerData._id}`,
        addVehicleData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // setIsLoading(false);
      alert("Success", "Vehicle data added successfully.");
      // window.history.back();
      navigate("/order/create")
    } catch (error) {
      console.log("Error:", error);
      // setIsLoading(false);
      alert("Error", "Failed to add vehicle data.");
    }
  }



  const orderData = {
    customer_name: inputs.customerName,
    product_name: inputs.productName,
    category: inputs.category,
    status: inputs.status,
    // description: inputs.description,
    total: inputs.total,
    final_total: inputs.finalTotal,
    // image,
    vehicle_type: inputs.category,
    make_model: inputs.vehicleMakeModel,
    verify_make_model: inputs.confirmVehicleMakeModel,
    reg_number: inputs.vehicleNumber,
    verify_reg_number: inputs.confirmVehicleNumber,
    verify_breakdown: inputs.confirmVehicleBreakDown,
    verify_vehicle_insurance: inputs.confirmVehicleInsurance,
    vehicle_insurance_status: inputs.vehicleInsuranceStatus,
    servicing_date: inputs.dateOfService,
    expected_delivery_date: inputs.expectedDeliveryDate,
    time_slot_service: inputs.timeSlotOfService,
    location: inputs.location,
    city: inputs.city,
    state: inputs.state,
    pincode: inputs.pincode,
    note: inputs.note,
    cusNumber: inputs.phone,
    altNumber: inputs.alternatePhone,
    email: inputs.email,
  };


  // console.table(orderData)



  const handleAddToAdminOrders = async () => {

    try {
      const res = await axios.post(`${apiUrl}/api/newmyorder/generate`, orderData, {
        headers: { "Content-Type": "application/json" },
      });

      console.log("Response from backend::", res.data);
      alert("Order added successfully at admin side orders.");
      // navigate("/orders");
    } catch (err) {
      console.error("Error occurred:", err);
      alert("Failed to save. Please try again.");
    }
  };

  const handleAddToCustomerMyOrders = async () => {
    // setIsLoading(true);

    try {
      if (!selectedCustomerData?._id) {
        console.error("No customer ID found");
        return;
      }
      const response = await axios.put(
        `${apiUrl}/api/customer/myorder/${selectedCustomerData?._id}`,
        { myOrders: orderData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Order added successfully at customer myorders!");
        // setIsLoading(false);
      } else {
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancelation
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error submitting order:", error.message);
      }
    }
    // setIsLoading(false);
  };



  const handleCombinedButtonClick = async (e) => {
    e.preventDefault();

    try {
      await handleAddToAdminOrders();
      await handleAddToCustomerMyOrders();
      alert("Orders saved successfully!");

      navigate("/orders");
    } catch (error) {
      console.error("Error in combined button click:", error);
      alert("An error occurred while saving. Please try again.");
    }
  };

  return (
    <div className="overflow-auto" style={{
      height: "95vh"
    }}>
      <div className="offset-lg-3 col-lg-6">
        {toggleForm === true ?
          (
            <div className="container">
              <div className="card">
                <div className="card-title d-flex justify-content-between">
                  <h2 className="m-0 ms-3">Add New Vehicle</h2>
                  <button className="btn btn-sm btn-primary m-0 me-3" onClick={handleToggleAddVehicle}>Create Order</button>
                </div>
                <div className="card-body">
                  <form >
                    <div className="col-lg-12  m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Customer name</label>
                        <input
                          required
                          list="customer-names"
                          value={inputs.customerName || ""}
                          onChange={(e) => handleInputChange("customerName", e.target.value)}
                          className="form-control"
                        ></input>
                      </div>
                      <datalist id="customer-names">
                        {recommendations.customerName.map((model, index) => (
                          <option key={index} value={model} />
                        ))}
                      </datalist>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Vehicle number</label>
                        <input
                          required
                          value={inputs.vehicleNumber || ""}
                          onChange={(e) => handleInputChange("vehicleNumber", e.target.value)}
                          className="form-control"
                          placeholder="e.g: MH 19 2C19"
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">
                          {/* {inputs.category === "Bike" ? "Bike" : inputs.category === "Car" ? "Car" : "Vehicle"} */}
                          Vehicle Make & Model</label>
                        <input
                          required
                          list="vehicle-models"
                          onChange={(e) => handleInputChange("vehicleMakeModel", e.target.value)}
                          value={inputs.vehicleMakeModel || ""}
                          //  onChange={(e) => setCustomer_id(e.target.value)}
                          className="form-control"
                        ></input>

                      </div>

                      <datalist id="vehicle-models">
                        {recommendations.vehicleMakeModel.map((model, index) => (
                          <option
                            key={index}
                            // label={`varient: ${model.variant}`}
                            value={`${model.make_model} - ${model.variant}`}
                          />
                        ))}
                      </datalist>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Vehicle Varient</label>
                        <input
                          required
                          value={addVehicleData.variant || ""}
                          // onChange={(e) => handleInputChange("vehicleNumber", e.target.value)}
                          className="form-control"
                          disabled
                        ></input>

                      </div>
                    </div>


                    <button className="btn btn-success w-100 m-0 mt-2 me-3 p-2 ps-3 pe-3" onClick={handleAddVehicle}>
                      Save
                    </button>


                    <div className="col-lg-12 m-0 mb-3 mt-5">
                      {inputs.customerName.length !== 0 && (<label className="p-0 pb-2 text-center d-block">{inputs.customerName.split(" ")[0]}'s vehicle list</label>)}
                      <div className="d-flex justify-content-between flex-wrap gap-4 m-0 mt-2 mb-2">
                        {
                          selectedCustomerVehicleData.length !== 0 && (

                            selectedCustomerVehicleData.map((item, index) => (
                              <SelectableVehicleCard
                                key={index}
                                data={item}
                                isSelectable={false}
                                isSelected={false}
                              />
                            ))
                          )
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          )
          :
          (
            <div className="container">
              <div className="card" style={{ textAlign: "left" }}>
                <div className="card-title d-flex justify-content-between">
                  <h2 className="m-0 ms-3">Order Create</h2>
                  <div>
                    <button className="btn btn-sm btn-secondary m-0 me-3" onClick={handleToggleAddVehicle}>Add Vehicle</button>
                    <button className="btn btn-sm btn-primary m-0 me-3" onClick={() => {
                      navigate("/customer/create")
                    }}
                    >Add Customer</button>
                  </div>
                </div>
                <div className="card-body">
                  <form>

                    <div className="col-lg-12  m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Customer name</label>
                        <input
                          required
                          list="customer-names"
                          value={inputs.customerName || ""}
                          onChange={(e) => handleInputChange("customerName", e.target.value)}
                          className="form-control"
                        ></input>
                      </div>
                      <datalist id="customer-names">
                        {recommendations.customerName.map((model, index) => (
                          <option key={index} value={model} />
                        ))}
                      </datalist>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Phone number</label>
                        <input
                          required
                          value={inputs.phone || ""}
                          // onChange={(e) => handleInputChange("phone", e.target.value)}
                          className="form-control"
                          disabled
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Email</label>
                        <input
                          type="email"
                          value={inputs.email || ""}
                          // onChange={(e) => handleInputChange("email", e.target.value)}
                          className="form-control"
                          disabled
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <label className="p-0 pb-2">Vehicle List</label>
                      <div className="d-flex justify-content-between flex-wrap gap-4 m-0 mt-2 mb-2">
                        {
                          selectedCustomerVehicleData.length !== 0 && (

                            selectedCustomerVehicleData.map((item, index) => (
                              <SelectableVehicleCard
                                key={index}
                                data={item}
                                isSelectable={true}
                                isSelected={inputs.vehicleMakeModel === item.make_model}
                                onClick={() => {
                                  setInputs((prev) => ({
                                    ...prev,
                                    category: item.vehicle_type,
                                    vehicleVariant: item.variant,
                                    vehicleMakeModel: item.make_model,
                                    vehicleNumber: item.reg_number,
                                    carType: item.car_type,
                                    bikeCC: item.bike_cc,
                                    total: 0,
                                    servicingType: "",
                                  }));
                                }}
                              />
                            ))
                          )
                        }

                        {selectedCustomerVehicleData.length === 0 && inputs.customerName.length !== 0 &&

                          (
                            <div className="w-100 d-flex flex-column align-items-center justify-content-between">
                              <h2 className="fs-6 text-center">You have not added a vehicle yet. Please click on "Add Vehicle."</h2>
                            </div>
                          )
                        }

                        {inputs.customerName.length === 0 &&

                          (
                            <div className="w-100 d-flex flex-column align-items-center justify-content-between">
                              <h2 className="fs-6 text-center text-secondary fw-medium">No vehicle list is present.</h2>
                            </div>
                          )
                        }
                      </div>
                    </div>


                    {selectedCustomerVehicleData.length !== 0 && (
                      <div className="col-lg-12 m-0 mb-3">
                        <div className="form-group">

                          <label className="p-0 pb-2">Vehicle Confirmation</label>

                          <div className="d-flex gap-3">
                            <input class="form-check-input"
                              type="checkbox"
                              checked={inputs.confirmVehicleMakeModel}
                              onClick={() => handleToggleCheckBoxInputFields(
                                'confirmVehicleMakeModel',
                                'Are you sure you want to change the value of Vehicle Make Model confirmation?'
                              )} />
                            <span className="p-0">Confirm Vehicle <strong>{`${inputs.vehicleMakeModel} ${inputs.vehicleVariant}`}</strong></span>
                          </div>
                          <div className="d-flex gap-3">
                            <input class="form-check-input"
                              type="checkbox"
                              checked={inputs.confirmVehicleNumber}
                              onClick={() => handleToggleCheckBoxInputFields(
                                'confirmVehicleNumber',
                                'Are you sure you want to change the value of Vehicle Registration Number confirmation?'
                              )} />
                            <span className="p-0"> Confirm Registration Number <strong>{`${inputs.vehicleNumber}`}</strong></span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">

                        <label className="p-0 pb-2">Vehicle Condition</label>
                        <div className="d-flex gap-3">
                          <input class="form-check-input"
                            type="checkbox"
                            checked={inputs.confirmVehicleBreakDown}
                            onClick={() => handleToggleCheckBoxInputFields(
                              'confirmVehicleBreakDown',
                              'Are you sure you want to change the value of Vehicle Breakdown?'
                            )} />
                          <span className="p-0">Vehicle Breakdown</span>
                        </div>
                        <div className="d-flex gap-3">
                          <input class="form-check-input"
                            type="checkbox"
                            checked={inputs.confirmVehicleInsurance}
                            onClick={() => handleToggleCheckBoxInputFields(
                              'confirmVehicleInsurance',
                              'Are you sure you want to change the value of Vehicle Under Insurance Servicing?'
                            )} />
                          <span className="p-0">Vehicle Insurance</span>

                        </div>

                        {inputs.confirmVehicleInsurance === true && (
                          <>
                            <div className="m-0 ms-4 mt-1 d-flex gap-3">
                              <input class="form-check-input"
                                type="radio"
                                name="insuranceStatus"
                                value="Valid"
                                checked={inputs.vehicleInsuranceStatus === "Valid"}
                                onClick={() => handleInsuranceStatusRadioChange(
                                  'Valid',
                                  'Are you sure you want to change the value of Vehicle Insurance Status?'
                                )}
                              />
                              <span className="p-0">Valid</span>

                            </div>

                            <div className="m-0 ms-4 mt-1 d-flex gap-3">
                              <input class="form-check-input"
                                type="radio"
                                name="insuranceStatus"
                                value="Invalid"
                                checked={inputs.vehicleInsuranceStatus === "Invalid"}
                                onClick={() => handleInsuranceStatusRadioChange(
                                  'Invalid',
                                  'Are you sure you want to change the value of Vehicle Insurance Status?'
                                )}
                              />
                              <span className="p-0">Invalid</span>
                            </div>
                          </>
                        )}

                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">City</label>
                        <select
                          required
                          onChange={(e) => handleInputChange("city", e.target.value)}
                          value={inputs.city || ""}
                          className="form-select"
                        >
                          <option value="" defaultValue={"Select city"} disabled>Select city</option>
                          <option value="Pune">Pune</option>
                          <option value="Nagpur">Nagpur</option>
                        </select>

                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Pickup Location</label>
                        <input
                          required
                          value={inputs.location || ""}
                          onChange={(e) => handleInputChange("location", e.target.value)}
                          className="form-control"
                        ></input>

                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Alternate phone number</label>
                        <input
                          required
                          value={inputs.alternatePhone || ""}
                          onChange={(e) => handleInputChange("alternatePhone", e.target.value)}
                          className="form-control"
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">State</label>
                        <input
                          required
                          value={inputs.state || ""}
                          // onChange={(e) => handleInputChange("state", e.target.value)}
                          disabled
                          className="form-control"
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Pincode</label>
                        <input
                          required
                          value={inputs.pincode || ""}
                          onChange={(e) => handleInputChange("pincode", e.target.value)}
                          className="form-control"
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Type of servicing</label>
                        <select
                          className="form-select"
                          id="data-filter"
                          onChange={(e) => handleInputChange("servicingType", e.target.value)}
                          value={inputs.servicingType || ""}
                        >
                          <option value="" defaultValue={"Select Filter"} disabled>Select service type</option>
                          {productNames.filter((product) => product.category.toLowerCase().includes(inputs.category.toLowerCase())).map((option, index) => (
                            <option key={index} value={`${option.product_name}`}>
                              {`${option.product_name} | ${option.category}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Total</label>
                        <input
                          required
                          value={inputs.total || 0}
                          onChange={(e) => handleInputChange("total", e.target.value)}
                          disabled
                          className="form-control"
                        ></input>

                      </div>

                      <div className="d-flex gap-3 m-0 mt-3">
                        <input class="form-check-input"
                          type="checkbox"
                          checked={inputs.confirmFinalTotal}
                          onClick={() => handleToggleCheckBoxInputFields(
                            'confirmFinalTotal',
                            'Are you sure you want to change the value of Confirm Total Value?'
                          )}
                        />
                        <span className="p-0">Confirm total value</span>
                      </div>

                      {inputs.confirmFinalTotal && (

                        <div className="form-group">
                          <label className="p-0 pb-2 m-0 mt-3">Final Total</label>
                          <input
                            required
                            value={inputs.finalTotal || 0}
                            // onChange={(e) => handleInputChange("total", e.target.value)}
                            disabled
                            className="form-control"
                          ></input>

                        </div>
                      )}
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Date of servicing</label>
                        <input
                          type="date"
                          required
                          min={minDate}
                          max={maxDate}
                          value={inputs.dateOfService || ""}
                          onChange={(e) => handleInputChange("dateOfService", e.target.value)}
                          className="form-control"
                        ></input>

                      </div>
                    </div>

                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Select Time Slot</label>
                        <select
                          className="form-select"
                          onChange={(e) => handleInputChange("timeSlotOfService", e.target.value)}
                          value={inputs.timeSlotOfService || ""}
                        >
                          <option value="" defaultValue={"Select a time slot"} disabled>
                            Select a time slot
                          </option>
                          {timeSlots.map((slot, index) => (
                            <option key={index} value={slot}>
                              {slot}
                            </option>
                          ))}
                        </select>

                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Expected Delivery Date</label>
                        <input
                          type="date"
                          required
                          min={minDate}
                          value={inputs.expectedDeliveryDate || ""}
                          onChange={(e) => handleInputChange("expectedDeliveryDate", e.target.value)}
                          className="form-control"
                        ></input>

                      </div>
                    </div>


                    <div className="col-lg-12 m-0 mb-3">
                      <div className="form-group">
                        <label className="p-0 pb-2">Note</label>
                        <textarea
                          // required
                          value={inputs.note || ""}
                          onChange={(e) => handleInputChange("note", e.target.value)}
                          className="form-control"
                          style={{
                            minHeight: "200px",
                            maxHeight: "200px",
                            resize: "none"
                          }}
                        ></textarea>

                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <button className="btn btn-success m-0 mt-2 me-3 p-2 ps-3 pe-3" onClick={handleCombinedButtonClick}>
                          Save
                        </button>

                        <button
                          className="btn btn-danger m-0 mt-2 me-3 p-2 ps-3 pe-3"
                          onClick={() => navigate(-1)}
                        >
                          Go back
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div >
            </div >
          )
        }
      </div >
    </div >
  );
};



const SelectableVehicleCard = memo(({ data, isSelected, onClick, isSelectable }) => {
  return (
    <div
      className={`card m-2 ${isSelected ? "border-primary" : ""}`}
      style={{
        width: "45%",
        cursor: isSelectable ? "pointer" : "default",
        border: isSelected ? "2px solid blue" : "1px solid gray",
      }}
      onClick={onClick}
    >
      <div className="card-body">
        <h2 className="card-title fs-6 text-dark">Vehicle: <span className="text-primary">{data.make_model}</span></h2>
        <h2 className="card-text fs-6 text-dark">Type: <span className="text-primary">{data.vehicle_type}</span></h2>
        <h2 className="card-text fs-6 text-dark">Variant: <span className="text-primary">{data.variant}</span></h2>
        <h2 className="card-text fs-6 text-dark">Reg. No: <span className="text-primary">{data.reg_number}</span></h2>
        {data.vehicle_type === "Bike" && (
          <h2 className="card-text fs-6 text-dark">CC: <span className="text-primary">{data.bike_cc}</span></h2>
        )}
        {data.car_type !== "Null" && (
          <h2 className="card-text fs-6 text-dark">Car Type: <span className="text-primary">{data.car_type}</span></h2>
        )}
      </div>
    </div>
  );
});


export default OrdCreate;
