// PostVehicle.jsx
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PostVehicle = () => {
  const [notificationVisible, setNotificationVisible] = useState(false);

  const [formData, setFormData] = useState({
    vehicle_type: "",
    make_model: "",
    variant: "",
    fuel_type: "",
    bike_cc: 0,
    car_type: "",
    vehicle_image:
      "https://img.freepik.com/premium-vector/traffic-sign-forbidden-entrance-car-motorcycle-german-traffic-sign-prohibition-traffic-ban-motorcycles-motor-vehicles-checked-transparent-background-vector-illustration-eps-10_157125-16005.jpg?w=740",
  });
  //   console.log("formData>>>:",formData)
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/api/vehicles/newvehicle",
        formData
      );
      console.log("Response:", response.data);

      // Show notification for 1 second
      setNotificationVisible(true);
      setTimeout(() => {
        setNotificationVisible(false);
      }, 1200);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <div
      className="card border-0 overflow-auto"
      style={{
        height: "92vh",
        position: "relative",
      }}
    >
      <h2>Post Vehicle Data</h2>
      <div
        style={{
          position: "absolute",
          top: "1.2rem",
          right: "1.3rem",
        }}
      >
        <button
          className="btn btn-sm m-0 btn-primary"
          style={{
            backgroundColor: "#007bff",
          }}
          onClick={() => navigate("/mapvehicles")}
        >
          View All Vehicles
        </button>
      </div>
      {notificationVisible && (
        <div
          style={{
            background: "green",
            color: "white",
            padding: "10px",
            textAlign: "center",
            marginTop: "10px",
            borderRadius: "5px",
          }}
        >
          Vehicle Added
        </div>
      )}
      <form onSubmit={handleSubmit} className="m-0 mt-4 d-flex flex-column">
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p
            style={{
              marginLeft: "0",
              width: "10%",
            }}
          >
            Vehicle Type:
          </p>
          <input
            type="text"
            name="vehicle_type"
            value={formData.vehicle_type}
            onChange={handleChange}
            required
            style={{
              // flex: "1",
              width: "100%",
              marginRight: "0",
              padding:"3px"
            }}
          />
        </label>

        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}>Make Model:</p>

          <input
            type="text"
            name="make_model"
            value={formData.make_model}
            onChange={handleChange}
            required
            style={{
              // flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}>Variant:</p>

          <input
            type="text"
            name="variant"
            value={formData.variant}
            onChange={handleChange}
            required
            style={{
              //  flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}>Fuel Type:</p>

          <input
            type="text"
            name="fuel_type"
            value={formData.fuel_type}
            onChange={handleChange}
            required
            style={{
              //  flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}> Bike CC:</p>

          <input
            type="number"
            name="bike_cc"
            value={formData.bike_cc}
            onChange={handleChange}
            required
            style={{
              // flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}> Car Type:</p>

          <input
            type="text"
            name="car_type"
            value={formData.car_type}
            onChange={handleChange}
            required
            style={{
              // flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "15px",
            // width: "100%",
            // border: "1px solid grey",
            // padding: "0 1%",
          }}
        >
          <p style={{ marginLeft: "0", width: "10%" }}>V_Image URL:</p>

          <input
            type="text"
            name="vehicle_image"
            value={formData.vehicle_image}
            onChange={handleChange}
            required
            style={{
              // flex: "1",
              marginRight: "0",
              width: "100%",
              padding:"3px"
            }}
          />
        </label>
        {/* Add more labels and inputs for other fields */}
        <button
          type="submit"
          style={{
            marginTop: "10px",
            width: "100%",
            padding: "10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Post Data
        </button>
      </form>
    </div>
  );
};

export default PostVehicle;
