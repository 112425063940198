import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiUrl } from "../../api/api";
import { FaEdit } from "react-icons/fa";
import { MdDetails, MdCall } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  // console.log("customers::", customers);

  // for pagination part
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // console.log("total pages::", totalPages);

  const [limit] = useState(6);
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current page from URL or default to 1
  const page = parseInt(searchParams.get("page")) || 1;


  const navigate = useNavigate();


  const LoadDetail = (_id) => navigate(`/customer/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/customer/edit/${_id}`);



  const RemoveFunction = (_id) => {
    if (window.confirm("Do you want to remove?")) {
      fetch(`${apiUrl}/api/customer/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok) {
            alert("Removed successfully.");
            window.location.reload();
          } else {
            throw new Error(
              `Failed to remove: ${res.status} ${res.statusText}`
            );
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    // for pagination pass page and limit to get response according to it.
    fetch(`${apiUrl}/api/customer/allcustomers?page=${page}&limit=${limit}`)
      .then((res) => res.json())
      .then((resp) => {
        setCustomers(resp.customers)
        // console.log("total customers", resp.totalCustomers);
        setTotalPages(resp.totalCustomers)

      })
      .catch((err) => console.log(err.message));
  }, [page]); // Only re-fetch when `page` changes


  // pagination btn handler
  const handlePageChange = (pageNumber) => {
    setSearchParams({ page: pageNumber });
  };

  // pagination page number display
  const renderPagination = () => {
    const start = Math.max(1, page - 2);
    const end = Math.min(totalPages, page + 2);
    const paginationItems = [];

    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item border-0 ${page === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
          style={{ cursor: 'pointer' }}
        >
          <span className={`page-link border-1 border-dark ${page === i ? "text-light" : "text-dark"}`}>{i}</span>
        </li>
      )
    };
    return paginationItems;
  }



  return (

    <div className="container-fluid m-0" >
      <div className="card overflow-auto"
        style={{
          height: "92vh"
        }}
      >
        <div className="card-title">
          <h2 className="m-0 ps-3">Customers</h2>
        </div>
        <div className="card-body">
          <div className="divbtn">
            <button
              onClick={() => { navigate("/customer/create") }}
              className="btn btn-success m-0 mb-4"
            >
              {"Add New Customer"}
            </button>
          </div>
          <table className="container-fluid" style={{ border: "1px solid #e4ff03", padding: "10px", background: "#fafae8" }}>
            <thead style={{ padding: "10px", background: "#FEC324", textTransform: "uppercase", fontWeight: "bold" }}
              claspan="">
              <tr style={{ textAlign: "center" }}>
                <th style={{ border: "1px solid black" }}>Sr No.</th>
                <th style={{ border: "1px solid black" }}>Cus Id</th>
                <th style={{ border: "1px solid black" }}>Customer Name</th>
                <th style={{ border: "1px solid black" }}>Phone</th>
                <th style={{ border: "1px solid black" }}>Email</th>
                <th style={{ border: "1px solid black" }}>City</th>
                <th style={{ border: "1px solid black" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((item, index) => (
                <tr key={item._id}>
                  <td
                    style={{ border: "1px solid black", textAlign: "center", padding: "10px" }}
                    claspan=""
                  >
                    {/* {item._id} */}
                    {/* {item && item._id ? item._id.slice(-4) : ""} */}
                    {/* {index + 1} */}

                    {index + 1 + (page - 1) * limit} {/* Updated index calculation */}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px"
                    }}
                    claspan=""
                  >
                    <span style={{ textTransform: "uppercase" }}>{item._id.slice(0, 8)}</span>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px"
                    }}
                    claspan=""
                  >
                    <span style={{ textTransform: "uppercase" }}>{item.name}</span>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px",

                    }}
                    claspan=""
                  >
                    <div style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems:"center"
                    }}>
                      <span style={{ textTransform: "uppercase",  fontWeight: item.phone_no ? "bold" : ""  }}>{item.phone_no || "N/A"}</span>
                      {item.phone_no && (
                        <button className="btn btn-success m-0 p-0 p-1 ps-2 pe-2">
                          <MdCall/>
                          </button>
                      )}
                    </div>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px"
                    }}
                    claspan=""
                  >
                    <span>{item.email}</span>
                  </td>

                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px"
                    }}
                    claspan=""
                  >
                    <span style={{ textTransform: "uppercase" }}>{item.myOrders[0]?.city}</span>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "10px"
                    }}
                    claspan=""
                  >
                    <div style={{
                      display: "flex",
                      justifyContent: "center", // Align items horizontally in the center
                      gap: "5px", // Space between the buttons
                    }}>

                      <button
                        style={{ margin: "1px" }}
                        onClick={() => LoadEdit(item._id)}
                        className="btn btn-success m-0 p-0 p-1 ps-2 pe-2"
                      >
                        <FaEdit />
                      </button>

                      <button
                        style={{ margin: "1px" }}
                        onClick={() => RemoveFunction(item._id)}
                        className="btn btn-danger m-0 p-0 p-1 ps-2 pe-2"
                      >
                        <RiDeleteBinLine />
                      </button>
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary m-0 p-0 p-1 ps-2 pe-2"
                      >
                        <AiOutlineOrderedList />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {/* Pagination Section */}
        <nav aria-label="Page navigation customer" className="mt-3">
          <ul className="pagination  justify-content-center">
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link text-dark border-dark"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >&laquo;</button>
            </li>
            {renderPagination()}
            <li className="page-item">
              <button
                className="page-link text-dark border-dark"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >&raquo;</button>
            </li>
          </ul>
        </nav>

      </div>
    </div>

  );
};

export default Customer;
