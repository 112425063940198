import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { MdDetails } from "react-icons/md";
import { apiUrl } from "../../api/api";


const Order = () => {
  const [orders, setOrders] = useState([]);
  const [notification, setNotification] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");

  // console.log("Orders", orders);
  
  // console.log("totalOrders::", orders);

  // for pagination part
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // console.log("total pages::", totalPages);

  const [limit] = useState(6);
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current page from URL or default to 1
  const page = parseInt(searchParams.get("page")) || 1;


  const navigate = useNavigate();

  const LoadDetail2 = (item) => {
    navigate(`/order/detail/${item._id}`, { state: { item } });
  };

  const handleServiceProvider = (_id) => navigate(`/serviceprovider`);

  const Removefunction = async (_id) => {
    if (window.confirm("Do you want to remove?")) {
      try {
        await axios.delete(
          `${apiUrl}/api/newmyorder/${_id}`
        );
        setOrders(orders.filter((order) => order._id !== _id));
        setNotification("Order deleted successfully");
        setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
      } catch (error) {
        console.error("Failed to delete order", error);
        setNotification("Failed to delete order");
        setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
      }
    }
  };



  useEffect(() => {
    const fetchOrders = async () => {
      try {
         // for pagination pass page and limit to get response according to it.
        const response = await axios.get(`${apiUrl}/api/newmyorder/getallnewmyorder?page=${page}&limit=${limit}`);
        const data = await response.data;
        // console.log("Order data", data.customers);
        setTotalPages(data.totalOrders);

        setOrders(data.customers);
        
        // sort orders
        // const sortedOrders = data.customers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        // setOrders(sortedOrders);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      }
    };

    fetchOrders();
  }, [page]); // Only re-fetch when `page` changes


  // pagination btn handler
  const handlePageChange = (pageNumber) => {
    setSearchParams({ page: pageNumber });
  };


  // pagination page number display
  const renderPagination = () => {
    const start = Math.max(1, page - 2);
    const end = Math.min(totalPages, page + 2);
    const paginationItems = [];

    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item border-0 ${page === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
          style={{ cursor: 'pointer' }}
        >
          <span className={`page-link border-1 border-dark ${page === i ? "text-light" : "text-dark"}`}>{i}</span>
        </li>
      )
    };
    return paginationItems;
  }




  const filteredOrders =
    selectedStatus === "all"
      ? orders
      : orders.filter((item) => item.status === selectedStatus);

  return (
    <div className="container-fluid m-0">
      {notification && <div className="notification">{notification}</div>}
      <div className="card overflow-auto"
        style={{
          height: "92vh"
        }}
      >
        <div className="card-title">
          <h2 className="m-0 ps-3">All Orders List</h2>
        </div>
        <div className="card-body">
          <div className="divbtn">
            <button
              onClick={() => navigate("/order/create")}
              className="btn btn-success m-0 mb-4"
            >
              Add New Order
            </button>
          </div>
          <div className="status-filter">
            <label htmlFor="status-filter" className="fs-4 m-0 mb-3">Filter by Status:</label>
            <select
              className="p-1 rounded-2 m-0 ms-2 mb-3"
              id="status-filter"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="all">All</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
              <option value="failed">Failed</option>
              <option value="processing">Processing</option>
            </select>
          </div>
          <table className="container-fluid" style={{ border: "1px solid #e4ff03", padding: "5px", background: "#fafae8" }}>
            <thead style={{ padding: "5px", background: "#FEC324", textTransform: "uppercase", fontWeight: "bold" }}>
              <tr style={{ textAlign: "center" }}>
                <th style={{ border: "1px solid black" }}>Sr No.</th>
                <th style={{ border: "1px solid black" }}>Order Id</th>
                <th style={{ border: "1px solid black" }}>City</th>
                <th style={{ border: "1px solid black" }}>Service Date</th>
                <th style={{ border: "1px solid black" }}>Service Time Slot</th>
                <th style={{ border: "1px solid black" }}>Vehicle Type</th>
                <th style={{ border: "1px solid black" }}>Service Name</th>
                <th style={{ border: "1px solid black" }}>Created on</th>
                <th style={{ border: "1px solid black" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((item, index) => (
                <tr key={item._id}>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {/* #{item._id.slice(-5)}  */}
                    {/* Order mongodb id */}

                    {index + 1 + (page - 1) * limit} 
                    {/* Updated index calculation */}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item._id.slice(0,8)}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.city}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.servicing_date}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.time_slot_service}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.vehicle_type}
                  </td>

                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.product_name[0]?.product_name}
                  </td>


                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    {item.createdAt.slice(0, 10)}
                  </td>

                  <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                    <div style={{
                      display: "flex",
                      justifyContent: "center", // Align items horizontally in the center
                      gap: "5px", // Space between the buttons
                    }}>

                      <button
                        style={{ margin: "1px" }}
                        onClick={() => Removefunction(item._id)}
                        className="btn btn-danger"
                      >
                        <RiDeleteBinLine />
                      </button>
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => LoadDetail2(item)}
                        className="btn btn-primary"
                      >
                        <AiOutlineOrderedList />
                      </button>
                      {item.status !== "cancelled" &&
                        item.status !== "completed" && (
                          <button
                            style={{
                              margin: "1px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px", // spacing between the icon and text
                            }}
                            onClick={() => handleServiceProvider(item._id)}
                            className="btn btn-warning"
                          >
                            <MdDetails />
                            Allot provider
                          </button>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Section */}
        <nav aria-label="Page navigation customer" className="mt-3">
          <ul className="pagination  justify-content-center">
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link text-dark border-dark"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >&laquo;</button>
            </li>
            {renderPagination()}
            <li className="page-item">
              <button
                className="page-link text-dark border-dark"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >&raquo;</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Order;
