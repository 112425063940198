import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../api/api";
import { useLocation } from "react-router-dom";

const OrdDetail = () => {
  const location = useLocation();
  const { item } = location.state;
  // console.log("itemxxxxxxxxxxxx", item);

  const navigate = useNavigate();

  return (


    <div style={{ margin: "40px" }}>
      <h2 style={{ marginBottom: "20px" }}>Order Details</h2>
      <table className="order-detail-table">
        <tbody>
          <tr>
            <th>Customer Name</th>
            <td style={{ textTransform: "uppercase" }}>{item.customer_name}</td>
          </tr>


          <tr>
            <th>Category</th>
            <td>{item.category}</td>
          </tr>
          <tr>
            <th>City</th>
            <td>{item.city}</td>
          </tr>
          <tr>
            <th>Phone Number</th>
            <td>{item.cusNumber}</td>
          </tr>
          <tr>
            <th>Alternate Phone Number</th>
            <td>{item.altNumber}</td>
          </tr>
          <br></br>
          <tr>
            <th>Service Name</th>
            <th>Total</th>
            <th>Location</th>
            <th>Reg No.</th>
            <th>Vehicle Model</th>
            <th>Spl Notes</th>

            <th>Servicing Date</th>
            <th>Servicing Time Slot</th>
            <th>Expected Delivery Date</th>

          </tr>
          <tr>
            <td>{item.product_name[0]?.product_name}</td>
            <td>{item.final_total}</td>
            <td>{item.location}</td>
            <td>{item.reg_number}</td>
            <td>{item.make_model}</td>
            <td>{item.note}</td>

            <td>{item.servicing_date}</td>
            <td>{item.time_slot_service}</td>
            <td>{item.expected_delivery_date}</td>
          </tr>


        </tbody>
      </table>

      <button className="btn btn-danger" onClick={() => navigate(-1)}>
        Go back
      </button>
    </div>
  );
};

export default OrdDetail;
