import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  CarOutlined,
  AuditOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Typography, Button, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { FcBusinessman } from "react-icons/fc";
import { FcDeployment } from "react-icons/fc";
import { FcServices } from "react-icons/fc";
import { FcSurvey } from "react-icons/fc";
import { IoIosShareAlt } from "react-icons/io";

// import axios from "axios";
import "./dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
} from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { apiUrl } from "../../api/api";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

function Dashboard() {
  const [orders, setOrders] = useState([]); // get orders data for graph purpose
  const [inventory, setInventory] = useState(0);
  const [customers, setCustomers] = useState([]); // get customer data for graph purpose 
  const [revenue, setRevenue] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);


  // console.log("Customers::", customers);
  // console.log("Orders::", orders);
  // console.log("totalCustomers::", totalCustomers);

  const [totalOrders, setTotalOrders] = useState(0);
  const [totalJobcart, setTotalJobcart] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalSubusers, setTotalSubusers] = useState(0);

  // console.log("totalOrders::", totalOrders);


  // const [orderss, setOrderss] = useState([]);
  // console.log("OrderData:", orderss);



  const [subUsersData, setSubUsersData] = useState([]);  // get Service provider data which is subusers for Service Provider Status pie chart.
  // console.log("Service Provider data", subUsersData);


  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedCityFilter, setSelectedCityFilter] = useState('');

  // console.log("Selected Filter::::", selectedFilter);

  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([]); // Store selected date range

  // console.log("Date range", dateRange);




  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'today', label: 'Today' },
    { value: 'lastDay', label: 'Yesterday' },
    { value: 'threeDaysBefore', label: 'Last 3 days' },
    { value: 'sevenDaysBefore', label: 'Last 7 days' },
    { value: 'fifteenDaysBefore', label: 'Last 15 days' },
    { value: 'oneMonthBefore', label: 'Last month' },
    { value: 'threeMonthsBefore', label: 'Last 3 months' },
    { value: 'nineMonthsBefore', label: 'Last 9 months' },
    { value: 'oneYearAgo', label: 'Last year' },
    { value: 'dateRange', label: 'Date Range' },
  ];

  const cityFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'pune', label: 'pune' },
    { value: 'nagpur', label: 'nagpur' },
  ]

  const handleCityFilterChange = (value) => {
    setSelectedCityFilter(value);

    if (value === "add") {
      // Re-filter orders based on city
      // const filteredOrders = orders.filter((order) => order.city.toLowerCase() === value);
      // setOrders(filteredOrders)
      // Re-fetch and filter orders from the original data source
      getmyOrdersData();
    } else {
      // If no city is selected, fetch and display all orders
      getmyOrdersData(value.toLowerCase());
    }
  }


  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    if (value !== "dateRange") {
      setDateRange({ start: "", end: "" }); // Clear date range when another filter is selected
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setSelectedFilter("dateRange");
    }
  };


  const getCustomersData = () => {
    fetch(`${apiUrl}/api/customer/allcustomers?all=true&filter=${selectedFilter}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Customer Data::", data.customers[0].vehicles[0].vehicle_type);

        setCustomers(data.customers);

        const customersCount = data.overallTotalCustomerCount;
        setTotalCustomers(customersCount);
      })
      .catch((error) => console.error(error));
  };

  const getmyOrdersData = (cityFilter = "") => {
    const rangeQuery = selectedFilter === "dateRange" && dateRange.length === 2
      ? `&range=${dateRange[0].toISOString()},${dateRange[1].toISOString()}`
      : '';

    fetch(`${apiUrl}/api/newmyorder/getallnewmyorder?all=true&filter=${selectedFilter}${rangeQuery}`)
      .then((response) => response.json())
      .then((data) => {

        let fetchedOrders = data.customers;
        // if (selectedCityFilter) {
        //   fetchedOrders = fetchedOrders.filter((order) => order.city.toLowerCase() === selectedCityFilter);
        // }

        // Apply city filter if provided
      if (cityFilter && cityFilter !== "all") {
        fetchedOrders = fetchedOrders.filter((order) => order.city.toLowerCase() === cityFilter);
      }
        setOrders(fetchedOrders)

        const ordersCount = data.overallTotalOrdersCount;
        setTotalOrders(ordersCount);
      })
      .catch((error) => console.error(error));
  };

  const getJobcartData = () => {
    fetch(`${apiUrl}/api/newjobcart`)
      .then((response) => response.json())
      .then((data) => {
        const jobcartCount = data.length;
        setTotalJobcart(jobcartCount);
      })
      .catch((error) => console.error(error));
  };

  const getProductsData = () => {

    fetch(`${apiUrl}/api/products/allproducts`)

      .then((response) => response.json())
      .then((data) => {
        const productsCount = data.length;
        setTotalProducts(productsCount);
      })
      .catch((error) => console.error(error));
  };

  const getServiceProviderData = () => {
    fetch(`${apiUrl}/api/subusers?all=true&filter=${selectedFilter}`)
      .then((response) => response.json())
      .then((data) => {
        setSubUsersData(data.subusers)
        const serviceproCount = data.overallTotalSubUsersCount;
        setTotalSubusers(serviceproCount);
      })
      .catch((error) => console.error(error));
  };



  useEffect(() => {

    if (selectedFilter === "dateRange" && (!dateRange[0] || !dateRange[1])) {
      // Don't fetch if date range is incomplete
      return;
    }

    getmyOrdersData();
    getCustomersData();
    getJobcartData();
    getProductsData();
    getServiceProviderData();
    // vehicleCountProcessData(customers);
  }, [selectedFilter, dateRange]);


  useEffect(() => {
    if (selectedCityFilter && selectedCityFilter !== "all") {
      const filteredOrders = orders.filter(
        (order) => order.city.toLowerCase() === selectedCityFilter
      );
      setOrders(filteredOrders);
    } else {
      getmyOrdersData();
    }
  }, [selectedCityFilter]);


  useEffect(() => {
    if (subUsersData.length > 0) {
      serviceProviderStatusProcessData(subUsersData);

    }

    if (orders.length > 0) {
      vehicleCountProcessData(orders);
    }

    if (orders.length > 0) {
      orderStatusProcessData(orders);
    }

    if (orders.length > 0) {
      cityChooseOnServicePurchasedData(orders);
    }

  }, [subUsersData, orders]);



  return (

    <Card
      className="container-fluid border-0 overflow-auto"
      style={{
        height: "95vh",
      }}
    >

      <Space
        size={40}
        direction="vertical"
      >
        <Typography.Title level={4} style={{ textAlign: 'center' }}>Grease Monkey Dashboard</Typography.Title>
        <Space direction="horizontal" size={40}>
          <Typography.Title level={3} className="m-0">Overview</Typography.Title>
          <select
            className="form-select"
            id="data-filter"
            onChange={(e) => handleFilterChange(e.target.value)} value={selectedFilter}
          >
            <option value="" defaultValue={"Select Filter"} disabled className="text-center">Filter by date</option>
            {filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {selectedFilter === "dateRange" && (
            <RangePicker
              className="m-0 text-dark border-secondary form-control d-flex"
              onChange={handleDateRangeChange}
              value={dateRange}
              style={{ marginTop: "10px", color: "black" }}
              format="YYYY-MM-DD"
            />
          )}

          <select
            className="form-select"
            id="data-filter"
            onChange={(e) => handleCityFilterChange(e.target.value)} value={selectedCityFilter}
          >
            <option value="" defaultValue={"Select Filter"} disabled className="text-center">Filter by city</option>
            {cityFilterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Space>

        <Space direction="horizontal" size={50}>
          <DashboardCard
            icon={
              <FcBusinessman
                size={70} />
            }
            title={<h3 style={{ fontSize: '18px', color: 'black' }}>Total Customers</h3>}
            value={totalCustomers}
          />
          <DashboardCard
            icon={
              <FcDeployment
                size={70} />
            }
            title={<h3 style={{ fontSize: '18px', color: 'black' }}>Total Orders</h3>}
            value={totalOrders}
          />
          <DashboardCard
            icon={
              <FcServices
                size={70} />
            }
            title={<h3 style={{ fontSize: '18px', color: 'black' }}>Total Service Providers</h3>}
            value={totalSubusers}
          />
          <DashboardCard
            icon={
              <FcSurvey
                size={70}
              />
            }
            title={<h3 style={{ fontSize: '18px', color: 'black' }}>Total Jobcards</h3>}
            value={totalJobcart}
          />


        </Space>
        <Space size={30}>
          <VehicleCountBarChart orders={orders} />
          <ServiceProviderStatusPieChart subUsersData={subUsersData} />
          <CityCountDisplayCard orders={orders} />
        </Space>
        <Space size={30}>
          <OrderStatusLineChart orders={orders} />
          <RecentOrders orders={orders} />
        </Space>
      </Space>
    </Card>
  );
}

function DashboardCard({ title, value, icon }) {
  return (
    <Card style={{ backgroundColor: "rgb(255, 236, 161)" }}>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value} />
      </Space>
    </Card>
  );
}

function RecentOrders({ orders }) {

  const [recentOrders, setRecentOrders] = useState([]);

  useEffect(() => {
    const sortedOrders = [...orders]
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 4);

    setRecentOrders(sortedOrders);
  }, [orders])

  // console.log("RecentOrders:::", recentOrders);


  // const navigate = useNavigate();

  // const LoadDetail2 = (item) => {
  //   navigate(`/order/detail/${item._id}`, { state: { item } });
  // };

  // const [loadings, setLoadings] = useState([]);

  // const LoadEdit = (id) => {
  //   setLoadings((prevLoadings) => {
  //     const newLoadings = [...prevLoadings];
  //     newLoadings[id] = true;
  //     return newLoadings;
  //   });
  // };

  // const enterLoading = (id) => {
  //   // console.log("ok::", id);
  //   setLoadings((prevLoadings) => {
  //     const newLoadings = [...prevLoadings];
  //     newLoadings[id] = true;
  //     return newLoadings;
  //   });



  //   setTimeout(() => {
  //     setLoadings((prevLoadings) => {
  //       const newLoadings = [...prevLoadings];
  //       newLoadings[id] = false;
  //       return newLoadings;
  //     });
  //   }, 3000);
  // };

  // const [dataSource, setDataSource] = useState([]);
  // // console.log("data,,,,:", dataSource);
  // const [loading, setLoading] = useState(false);
  // const [totalOrd, setTotalOrd] = useState(0);
  // // console.log("totalOrd", totalOrd);
  // dataSource.map((item) => {
  //   totalOrd += +item.total;

  // });



  // useEffect(() => {
  //   setLoading(true);
  //   apiUrl().then((res) => {
  //     const data = res;
  //     setDataSource(data);
  //     setLoading(false);
  //   });
  // }, []);

  // setTotalOrd(totalOrd);



  return (
    <Card className="border bg-light" style={{
      width: "38vw",
      height: "45vh",
    }}>
      <h2 className="fs-6 fw-medium text-center">Recent Orders</h2>


      {/* <div className="status-filter">
        <label htmlFor="status-filter" className="fs-6 m-0 mb-3">Filter by:</label>
        <select
          className="p-0 ps-1 pe-1 rounded-1 m-0 ms-2 mb-3"
          id="status-filter"
        // value={selectedStatus}
        // onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="service">Servicing</option>
          <option value="breakdown">Break down</option>
          <option value="insurance">Insurance</option>
          <option value="vehicle">Vehicle</option>
        </select>
      </div> */}


      <table className="container-fluid" style={{ border: "1px solid #e4ff03", padding: "10px", background: "#fafae8" }}>
        <thead style={{ padding: "10px", background: "#FEC324", textTransform: "capitalize", fontWeight: "bold" }}
          claspan="">
          <tr style={{ textAlign: "center" }}>
            <th style={{ border: "1px solid black", padding: "4px" }}>Order Id</th>
            <th style={{ border: "1px solid black", padding: "4px" }}>Status</th>
            <th style={{ border: "1px solid black", padding: "4px" }}>Service Date</th>
            <th style={{ border: "1px solid black", padding: "4px" }}>Vehicle type</th>
            <th style={{ border: "1px solid black", padding: "4px" }}>Service Time Slot</th>
            {/* <th style={{ border: "1px solid black", padding: "4px" }}>Checkout</th> */}
          </tr>
        </thead>
        <tbody>
          {/* {dataSource.map((item) => { */}
          {recentOrders.map((item, index) => {
            // setTotalOrd(totalOrd + +item.total);
            return (
              <tr
                // key={item._id}
                key={index}
              >
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }} >
                  {item._id.slice(0, 8)}
                </td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                  {item.status}
                </td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                  {item.servicing_date}
                </td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                  {/* {item.billing.phone} */}
                  {item.vehicle_type}
                </td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                  {item.time_slot_service}
                </td>
                {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
                  <Button
                    // className="btn btn-primary"
                    type="primary"
                  // loading={loadings[0]}
                  onClick={() => LoadDetail2(item)}
                  >
                    <IoIosShareAlt size={20} />
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}


function DashboardChart() {
  const [reveneuData, setReveneuData] = useState({
    labels: [],
    datasets: [],
  });


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Order Revenue",
      },
    },
  };

  return (
    <Card style={{ width: 500, height: 250 }}>
      <Bar options={options} data={reveneuData} />
    </Card>
  );
}


function Products() {
  const [services, setServices] = useState([]);
  // console.log("services:", services);

  useEffect(() => {
    fetch(`${apiUrl}/products`)
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error("Failed to fetch services", error));
  }, []);

  return (
    <>
      <Typography.Text>Recent Orders</Typography.Text>

      {services.map((item) => (
        <p key={item.id}>
          <p colSpan="2">{item.id}</p>
          <ul dangerouslySetInnerHTML={{ __html: item.short_description }} />
        </p>
      ))}
    </>
  );
}






// -------------------------------------------------------------------------------

// Card to show Pune and Nagpur city counts when customer select city on service purchased order data

const CityCountDisplayCard = ({ orders }) => {
  const { Pune, Nagpur } = cityChooseOnServicePurchasedData(orders);


  const data = {
    labels: [`Pune`, `Nagpur`],

    datasets: [
      {
        label: "Count",
        data: [Pune, Nagpur],
        backgroundColor: ["rgba(255, 206, 86, 1)", "rgba(255, 159, 64, 1)"], // Customize colors
        hoverBackgroundColor: ["rgba(255, 206, 86, 1)", "rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      }
    ]
  }

  return (
    <Card className="border bg-light" style={{
      width: "18vw",
      height: "45vh"
    }}>
      <h2 className="fs-6 text-center m-0 p-0 mb-3">City Count In Orders Overview</h2>
      {/* <p className="text-center m-0 p-0" style={{
        fontSize: "0.8rem"
      }}>City choosen on order purchased.</p> */}
      <Pie data={data} />
    </Card>
  );
}

const cityChooseOnServicePurchasedData = (orders) => {
  const cityCounts = { Pune: 0, Nagpur: 0 };

  orders.forEach((order) => {
    // customer.myOrders.forEach((myOrder) => {
    const city = order.city.toLowerCase();
    if (city === "pune") {
      cityCounts.Pune += 1;
    } else if (city === "nagpur") {
      cityCounts.Nagpur += 1;
    }
    // })
  })


  // console.log("Pune Count::", cityCounts.Pune);
  // console.log("Nagpur Count::", cityCounts.Nagpur);

  return cityCounts;

}

// -------------------------------------------------------------------------------





// -------------------------------------------------------------------------------

// Line chart for Orders status.
// Note: Here, it will shows dots not lines as to create a line there will selecting two dots to draw line. Hence, in these chart no line will be displayed.

const OrderStatusLineChart = ({ orders }) => {
  const { Active, Inactive, Completed, Cancelled, Failed, Processing } = orderStatusProcessData(orders);

  const data = {
    labels: ["Active", "Inactive", "Completed", "Cancelled", "Failed", "Processing"], // Label for each order status
    datasets: [
      {
        label: 'Active',
        data: [Active, null, null, null, null, null], // Data for "Active" only
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 1)', // Fill color
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
      {
        label: 'Inactive',
        data: [null, Inactive, null, null, null, null], // Data for "Inactive" only
        borderColor: 'rgba(255, 99, 132, 1)',
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
      {
        label: 'Completed',
        data: [null, null, Completed, null, null, null], // Data for "Completed" only
        borderColor: 'rgba(54, 162, 235, 1)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
      {
        label: 'Cancelled',
        data: [null, null, null, Cancelled, null, null], // Data for "Cancelled" only
        borderColor: 'rgba(255, 206, 86, 1)',
        pointBackgroundColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
      {
        label: 'Failed',
        data: [null, null, null, null, Failed, null], // Data for "Failed" only
        borderColor: 'rgba(153, 102, 255, 1)',
        pointBackgroundColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
      {
        label: 'Processing',
        data: [null, null, null, null, null, Processing], // Data for "Processing" only
        borderColor: 'rgba(255, 159, 64, 1)',
        pointBackgroundColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 2,
        pointRadius: 4,
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    // plugins: {
    //   legend: {
    //     display: true,
    //     position: 'top',
    //   },
    //   title: {
    //     display: true,
    //     text: 'Order Status Overview',
    //   },
    // },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          display: true,
          text: 'Order Status Count', // X-axis label
        },
        ticks: {
          stepSize: 1, // Ensure x-axis increments by 1
          beginAtZero: true, // Start at 0
        },
      },
      y: {
        title: {
          display: true,
          text: 'Order Status', // Y-axis label
        },
      },
    },
  };


  return (
    <Card
      className="border bg-light"
      style={{
        width: "38vw",
        height: "45vh"
      }}>
      <h2 className="fs-6 text-center m-0 p-0">Order Status Overview</h2>
      <Line data={data} options={options} />
    </Card>
  );
}

const orderStatusProcessData = (orders) => {
  const orderStatus = { Active: 0, Inactive: 0, Completed: 0, Cancelled: 0, Failed: 0, Processing: 0 };

  orders.forEach((order) => {
    if (order.status === "Active") {
      orderStatus.Active += 1;
    } else if (order.status === "Inactive") {
      orderStatus.Inactive += 1;
    } else if (order.status === "Completed") {
      orderStatus.Completed += 1;
    } else if (order.status === "Cancelled") {
      orderStatus.Cancelled += 1;
    } else if (order.status === "Failed") {
      orderStatus.Failed += 1;
    } else if (order.status === "Processing") {
      orderStatus.Processing += 1;
    }
  })

  // console.log("Active::", orderStatus.Active);
  // console.log("Inactive::", orderStatus.Inactive);
  // console.log("Completed::", orderStatus.Completed);
  // console.log("Cancelled::", orderStatus.Cancelled);
  // console.log("Failed::", orderStatus.Failed);
  // console.log("Processing::", orderStatus.Processing);

  return orderStatus;
}

// -------------------------------------------------------------------------------





// -------------------------------------------------------------------------------

// Bar chart for showing Bike and Car count.

const VehicleCountBarChart = ({ orders }) => {
  const { bike, car } = vehicleCountProcessData(orders);

  const data = {
    labels: [" "],
    datasets: [
      {
        label: 'Bike', // Label for Bike
        data: [bike], // Data for Bike
        backgroundColor: 'rgba(75, 192, 192, 1)', // Color for Bike
        borderColor: 'rgba(75, 192, 192, 0.2)', // Border color for Bike
        borderWidth: 1,
      },
      {
        label: 'Car', // Label for Car
        data: [car], // Data for Car
        backgroundColor: 'rgb(153, 102, 255)', // color for Car
        borderColor: 'rgba(153, 102, 255, 0.2)', // Border color for Car
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          display: true,
          text: 'Number of vehicles', // X-axis label
        },
        ticks: {
          stepSize: 1, // Ensure x-axis increments by 1
          beginAtZero: true, // Start at 0
        },
      },
      y: {
        title: {
          display: true,
          text: 'Types of Vehicles', // Y-axis label
        },
      },
    },
  };

  return (
    <Card
      className="border bg-light"
      style={{
        width: "38vw",
        height: "45vh"
      }}
    >
      <h2 className="fs-6 text-center m-0 p-0">Vehicles Count In Orders Overview</h2>
      <Bar data={data} options={options} />
    </Card>
  );

}

const vehicleCountProcessData = (orders) => {
  const vehicleCounts = { bike: 0, car: 0 }; // get vehicle count for displaying on bar graph.

  // data.customers[0].vehicles[0].vehicle_type
  orders.forEach((order) => {
    // customer.vehicles.forEach((vehicle) => {
    if (order.category === "Bike") {
      vehicleCounts.bike += 1;
    } else if (order.category === "Car") {
      vehicleCounts.car += 1;
    }
    // })
  })

  // console.log("Bike Count", vehicleCounts.bike);
  // console.log("Car Count", vehicleCounts.car);
  return vehicleCounts;

}

// -------------------------------------------------------------------------------





// -------------------------------------------------------------------------------

// Pie chart for showing Service Provider Status

const ServiceProviderStatusPieChart = ({ subUsersData }) => {
  const { Active, Inactive } = serviceProviderStatusProcessData(subUsersData);

  const data = {
    labels: [`Active`, `Inactive`],

    datasets: [
      {
        label: "Count",
        data: [Active, Inactive],
        backgroundColor: ["#36A2EB", "#FF6384"], // Customize colors
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
        borderWidth: 1,
      }
    ]
  }

  return (
    <Card className="border bg-light" style={{
      width: "18vw",
      height: "45vh"
    }}>
      <h2 className="fs-6 text-center m-0 p-0">Service Providers Status Overview</h2>
      <Doughnut data={data} />
    </Card>
  );
}

const serviceProviderStatusProcessData = (subUsersData) => {
  const statusCounts = { Active: 0, Inactive: 0 };
  // console.log("subUsersData", subUsersData);


  subUsersData.forEach((subuser) => {
    if (subuser.status === true) {
      statusCounts.Active += 1;
    } else if (subuser.status === false) {
      statusCounts.Inactive += 1;
    }
  });

  // console.log("Active", statusCounts.Active);
  // console.log("Inactive", statusCounts.Inactive);
  return statusCounts;
}

// -------------------------------------------------------------------------------




export default Dashboard;
// export { Dashboard, DashboardChart, RecentOrders };