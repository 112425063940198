import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../api/api";
const CusCreate = () => {
  const navigate = useNavigate();
  // const [id] = useState("");
  const [name, namechange] = useState("");
  const [email, emailchange] = useState("");
  const [password, passwordchange] = useState("");
  const [phone_no, phone_nochange] = useState("");
  // const [city, setCity] = useState("");
  const [validation, valchange] = useState(false);

  const [toggleCreateOrderBtn, setToggleCreateOrderBtn] = useState(false);

  const handlesubmit = (e) => {
    e.preventDefault();
    const newCustomerdata = {
      name,
      email,
      phone_no,
      password,

    };

    console.log(newCustomerdata);


    const apiRequest1 = fetch(`${apiUrl}/api/customer/register`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(newCustomerdata),
    });

    Promise.all([apiRequest1])
      .then((responses) => {
        // Handle the responses here
        const [response1] = responses;
        if (response1.ok) {
          alert("Saved successfully.");
          // navigate("/customers");
          setToggleCreateOrderBtn(true)
        } else {

          console.log("Request failed.");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="overflow-auto" style={{
      height: "95vh"
    }}>
      <div className="offset-lg-3 col-lg-6">
        <div className="container">
          <div className="card" style={{ textAlign: "left" }}>
            <div className="card-title d-flex justify-content-between">
              <h2 className="m-0 ms-3">Add New Customer</h2>
            </div>
            <div className="card-body">

              <form onSubmit={handlesubmit}>
                <div className="col-lg-12 m-0 mb-3">
                  <div className="form-group">
                    <label className="p-0 pb-2">Full Name</label>
                    <input
                      required
                      value={name}
                      onMouseDown={(e) => valchange(true)}
                      onChange={(e) => namechange(e.target.value)}
                      className="form-control"
                    ></input>
                    {name.length === 0 && validation && (
                      <span className="text-danger">Enter the name</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 m-0 mb-3">
                  <div className="form-group">
                    <label className="p-0 pb-2">Email</label>
                    <input
                      required
                      value={email}
                      onChange={(e) => emailchange(e.target.value)}
                      className="form-control"
                      type="email"
                    ></input>
                  </div>
                </div>

                <div className="col-lg-12 m-0 mb-3">
                  <div className="form-group">
                    <label className="p-0 pb-2">Phone</label>
                    <input
                      required
                      value={phone_no}
                      onChange={(e) => phone_nochange(e.target.value)}
                      className="form-control"
                    // type="tel"
                    ></input>
                  </div>
                </div>



                <div className="col-lg-12 m-0 mb-3">
                  <div className="form-group">
                    <label className="p-0 pb-2">Password</label>
                    <input
                      required
                      value={password}
                      onChange={(e) => passwordchange(e.target.value)}
                      className="form-control"
                      type="password"
                    ></input>
                  </div>
                </div>


                <div className="col-lg-12">
                  <div className="form-group">
                    <button className="btn btn-success m-0 mt-2 me-3 p-2 ps-3 pe-3" type="submit">
                      Save
                    </button>
                    {toggleCreateOrderBtn && (
                      <button className="btn btn-primary m-0 mt-2 me-3 p-2 ps-3 pe-3" onClick={()=>{
                        navigate("/order/create");
                        setToggleCreateOrderBtn(false)
                      }}>
                      Create Order
                    </button>
                    )}

                    <button
                      className="btn btn-danger m-0 mt-2 me-3 p-2 ps-3 pe-3"
                      onClick={() => {
                        navigate(-1);
                        setToggleCreateOrderBtn(false)
                      }}
                    >
                      Go back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CusCreate;
