import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDetails } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";

const AllotProvider = () => {
  const [data, setData] = useState([]);
  // console.log("data:", data);
  const garageData = data.filter((item) => item.position === "garage");
  console.log("garageData:", garageData);
  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/garage/detail/${_id}`);

  const AllotOrder = (_id) => navigate(`/garage/allot/${_id}`);
  const AllotedOrders = (_id) => navigate(`/garage/allotedorders/${_id}`);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userInfo")) || {};
    const token = data.token;
    fetch("https://gmi-server-1.onrender.com/api/subusers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.customers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <div className="container">
        <div className="card">
          <div className="card-title">
            <h2>Service Provider List</h2>
          </div>
          <div className="card-body">
            <table className="">
              <thead className="bg-dark text-white">
                <tr style={{ textAlign: "center" }}>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    title
                  </td>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    first_name
                  </td>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    last_name
                  </td>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    Phone
                  </td>
                  {/* <td  style={{ border: "1px solid black", padding: "2px" }}>Orders</td> */}
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {garageData.map((item) => (
                  <tr key={item._id}>
                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                      claspan=""
                    >
                      {item.title}
                    </td>
                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                      claspan=""
                    >
                      {item.first_name}
                    </td>
                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                      claspan=""
                    >
                      {item.last_name}
                    </td>
                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                      claspan=""
                    >
                      {item.phone}
                    </td>

                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                      claspan=""
                    >
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary"
                      >
                        <AiOutlineOrderedList />
                      </button>

                      <button
                        style={{ margin: "1px" }}
                        onClick={() => AllotOrder(item._id)}
                        className="btn btn-warning"
                      >
                        <MdDetails />
                        Allot to Order
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllotProvider;
