import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdCall } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubmitOrderDetailForm from "./SubmitOrderDetailForm";
import { apiUrl } from "../../api/api";

const ApplyOrderList = () => {
  const [data, setData] = useState("");
  // console.log("data:",data)


  // for pagination part
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(6);
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current page from URL or default to 1
  const page = parseInt(searchParams.get("page")) || 1;


  const navigate = useNavigate();

  const JobCartPage = (item) => {
    setData([
      item._id,
      item.customerInfo?.[0]?.customer_name,
      item.customerInfo?.[0]?.cusNumber,
      item.customerInfo?.[0]?.final_total,
      item.customerInfo?.[0]?.location,
      item.serviceProInfo?.[0]?.id,
      item.customerInfo?.[0]?.servicing_date,
      item.customerInfo?.[0]?.reg_number,
    ]);
  };

  const [products, setProducts] = useState([]);

  console.log("Products:::", products);


  const BikeData = products.filter((item) => item.status === "completed");

  useEffect(() => {
    fetch(`${apiUrl}/api/completedorder?page=${page}&limit=${limit}`)
      .then((res) => res.json())
      .then((resp) => {
        setProducts(resp.completedOrderData)
        setTotalPages(resp.totalCompletedOrders)
      })
      .catch((err) => console.log(err.message));
  }, [page]); // Only re-fetch when `page` changes


  // pagination btn handler
  const handlePageChange = (pageNumber) => {
    setSearchParams({ page: pageNumber });
  };

  // pagination page number display
  const renderPagination = () => {
    const start = Math.max(1, page - 2);
    const end = Math.min(totalPages, page + 2);
    const paginationItems = [];

    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item border-0 ${page === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
          style={{ cursor: 'pointer' }}
        >
          <span className={`page-link border-1 border-dark ${page === i ? "text-light" : "text-dark"}`}>{i}</span>
        </li>
      )
    };
    return paginationItems;
  }



  return (
    <div className="overflow-auto p-2" style={{
      height: "92vh"
    }}>
      <SubmitOrderDetailForm data={data} />
      <br />
      <hr />
      <h4>Completed Orders list</h4>
      <table className="container-fluid">
        <thead className="bg-dark text-white">
          <tr>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              #_id
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              customer_name
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              customer_number
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              final_total
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              location
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              reg_number
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              servicing_date
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              service pro ID
            </th>
            <th className="text-dark" style={{ border: "1px solid black", textAlign: "center", textTransform: "capitalize" }} colSpan="1">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((item, index) => (
            <tr key={item._id}>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {/* #_{item._id} */}
                {/* #_{item._id.slice(-4)} */}

                {index + 1 + (page - 1) * limit} {/* Updated index calculation */}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.customerInfo?.[0]?.customer_name || "N/A"}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {/* {item.customerInfo?.[0]?.cusNumber?.toString().slice(-3) ||
                  "N/A"} */}
                <div style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center"
                }}>
                  <span style={{ textTransform: "uppercase", fontWeight: item.customerInfo?.[0]?.cusNumber?.toString() ? "bold" : "" }}> 
                    {item.customerInfo?.[0]?.cusNumber?.toString() || "N/A"}
                  </span>
                  {item.customerInfo?.[0]?.cusNumber?.toString() && (
                    <button className="btn btn-success m-0 p-0 p-1 ps-2 pe-2">
                      <MdCall />
                    </button>
                  )}
                </div>
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.customerInfo?.[0]?.final_total || "N/A"}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.customerInfo?.[0]?.location || "N/A"}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.customerInfo?.[0]?.reg_number || "N/A"}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.customerInfo?.[0]?.servicing_date || "N/A"}
              </td>
              <td className="border1" style={{ textAlign: "center" }} colSpan="">
                {item.serviceProInfo?.[0]?.id.slice(0, 8) || "N/A"}
              </td>
              <td className="border1">
                <button
                  onClick={() => JobCartPage(item)}
                  className="btn btn-success"
                >
                  Apply
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* Pagination Section */}
      <nav aria-label="Page navigation customer" className="mt-3">
        <ul className="pagination  justify-content-center">
          <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link text-dark border-dark"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >&laquo;</button>
          </li>
          {renderPagination()}
          <li className="page-item">
            <button
              className="page-link text-dark border-dark"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >&raquo;</button>
          </li>
        </ul>
      </nav>

    </div>


  );
};

export default ApplyOrderList;
