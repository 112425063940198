import React, { useEffect, useState } from "react";
// import MainScreen from "../../components/MainScreen";
import { Button, Card, Form, Accordion } from "react-bootstrap";
import { apiUrl } from "../../api/api";

function SubUserRegister() {
  // const [title, setTitle] = useState("");
  // const [position, setPosition] = useState("");
  // const [first_name, setfirst_name] = useState("");
  // const [last_name, setlast_name] = useState("");
  // const [area, setarea] = useState("");
  // const [phone, setphone] = useState("");
  // const [email, setemail] = useState("");
  // const [password, setpassword] = useState("");

  const city = [
    "Pune",
    "Nagpur",
    "Pimpri-Chinchwad"
  ]

  const serviceCoverArea = [
    "2 km",
    "3 km",
    "4 km",
    "5 km",
    "10 km",
    "Depends on requirements",
  ]

  const selectShopOrWorkTime = [
    "8am to 8pm",
    "8am to 9pm",
    "8am to 10pm",
    "9am to 8pm",
    "9am to 9pm",
    "9am to 10pm"
  ]

  const selectTwoWheelerType = [
    "Upto 150cc",
    "Upto 220cc",
    "Sports Bike",
    "Royal Enfield",
    "EV",
    "All types of vehicles",
  ]

  const selectFourWheelerType = [
    "Sedan - Hatchback",
    "SUV",
    "EV",
    "Light commercial vehicles",
    "Commerical vehicles",
  ]


  const twoWheelerWithoutOilRows = ["Upto 150cc", "Upto 220cc", "Sports Bike", "Royal Enfield", "EV"];
  const twoWheelerWithoutOilColumns = [
    "250-300",
    "300-350",
    "350-400",
    "400-500",
    "Above 500",
  ];

  const twoWheelerWithOilRows = ["Upto 150cc", "Upto 220cc", "Sports Bike", "Royal Enfield"];
  const twoWheelerWithOilColumns = [
    "600-700",
    "700-800",
    "800-1000",
    "1200-1500",
    "Above 1500"
  ];



  const carBasicServicingDescription = `
  ✓ Vehicle ECG Report (Parts Wear and Tear Ratio, Record service details and next service date and Validity)
  ✓ Free Pickup & Drop
  ✓ Brakes: Test for responsiveness and stopping power (Front and rear). Inspect pads for wear and tear. Check lines and hoses for leaks and damage.
  ✓ Tyre Pressure and Condition: Measure pressure against manufacturer's specs. Inspect tyres for tread depth, cracks, uneven wear.
  ✓ Wheels and Rims: Check wheels for alignment, damage, and loose lug nuts. Inspect rims for bends and cracks.
  ✓ Lights: Test all lights (headlights, taillights, brake lights, turn signals) for proper operation.
  ✓ Horn: Test for proper functioning and sound level.
  ✓ Mirrors: Check proper adjustment and secure attachment.
  ✓ Windshield Wipers and Fluid: Check blades for wear and tear and test washer spray operation.
  ✓ Steering: Check for excessive play and smooth operation.
  ✓ Engine Oil: Check level and condition (colour, viscosity).
  ✓ Brake Fluid: Check level and condition.
  ✓ Coolant: Check level and condition (colour, strength).
  ✓ Power Steering Fluid: Check level and condition.
  ✓ Windshield Washer Fluid: Check level and refill if needed.
  ✓ Battery: Check terminals for corrosion and tightness. Measure battery voltage.
  ✓ Air Filter: Inspect for dirt and debris, Clean.
  ✓ Wiper Blades: Check for wear and tear.
  ✓ Exhaust System: Inspect for leaks, damage, and loose mounting.
  ✓ Suspension: Check for leaks, damage, and proper damping.
  ✓ Electrical System: Check all connections for security and damage.
  ✓ License Plate and Registration: Ensure they are valid and securely attached.
  ✓ Test Drive: Take a short test drive to assess overall performance and identify any remaining issues.
`;


  const carAdvancedServicingDescription = `
  ✓ Vehicle ECG Report (Parts Wear and Tear Ratio, Record service details and next service date and Validity)
  ✓ Free Pickup & Drop
  ✓ Engine Oil Replacement 3.5 Litre
  ✓ Oil Filter Change
  ✓ Air Filter Change
  ✓ Brake Cleaning
  ✓ Calliper Greasing
  ✓ Brakes: Test for responsiveness and stopping power (Front and rear). Inspect pads for wear and tear. Check lines and hoses for leaks and damage.
  ✓ Tyre Pressure and Condition: Measure pressure against manufacturer's specs. Inspect tyres for tread depth, cracks, uneven wear
  ✓ Wheels and Rims: Check wheels for alignment, damage, and loose lug nuts. Inspect rims for bends and cracks.
  ✓ Lights: Test all lights (headlights, taillights, brake lights, turn signals) for proper operation.
  ✓ Horn: Test for proper functioning and sound level.
  ✓ Mirrors: Check proper adjustment and secure attachment.
  ✓ Windshield Wipers and Fluid: Check blades for wear and tear and test washer spray operation.
  ✓ Steering: Check for excessive play and smooth operation.
  ✓ Brake Fluid: Check level and condition.
  ✓ Coolant: Check level and condition (colour, strength).
  ✓ Power Steering Fluid: Check level and condition
  ✓ Windshield Washer Fluid: Check level and refill if needed.
  ✓ Battery: Check terminals for corrosion and tightness. Measure battery voltage.
  ✓ Wiper Blades: Check for wear and tear.
  ✓ Exhaust System: Inspect for leaks, damage, and loose mounting.
  ✓ Suspension: Check for leaks, damage, and proper damping.
  ✓ Electrical System: Check all connections for security and damage.
  ✓ License Plate and Registration: Ensure they are valid and securely attached.
  ✓ Test Drive: Take a short test drive to assess overall performance and identify any remaining issues.
`;


  const carProServicingDescription = `
  ✓ Vehicle ECG Report (Parts Wear and Tear Ratio, Record service details and next service date and Validity)
  ✓ Free Pickup & Drop
  ✓ Engine Oil Replacement 3.5 Litre
  ✓ Oil Filter Change
  ✓ Air Filter Change
  ✓ Brake Cleaning
  ✓ Calliper Greasing
  ✓ All Electric Check-up,
  ✓ Total Scanning,
  ✓ Ac Gas Top-up,
  ✓ Alignment and Balancing,
  ✓ Tyre Rotation
  ✓ Brakes: Test for responsiveness and stopping power (Front and rear). Inspect pads for wear and tear. Check lines and hoses for leaks and damage.
  ✓ Tyre Pressure and Condition: Measure pressure against manufacturer's specs. Inspect tyres for tread depth, cracks, uneven wear
  ✓ Wheels and Rims: Check wheels for alignment, damage, and loose lug nuts. Inspect rims for bends and cracks.
  ✓ Lights: Test all lights (headlights, taillights, brake lights, turn signals) for proper operation.
  ✓ Horn: Test for proper functioning and sound level.
  ✓ Mirrors: Check proper adjustment and secure attachment.
  ✓ Windshield Wipers and Fluid: Check blades for wear and tear and test washer spray operation.
  ✓ Steering: Check for excessive play and smooth operation.
  ✓ Brake Fluid: Check level and condition.
  ✓ Coolant: Check level and condition (colour, strength).
  ✓ Power Steering Fluid: Check level and condition
  ✓ Windshield Washer Fluid: Check level and refill if needed.
  ✓ Battery: Check terminals for corrosion and tightness. Measure battery voltage.
  ✓ Wiper Blades: Check for wear and tear.
  ✓ Exhaust System: Inspect for leaks, damage, and loose mounting.
  ✓ Suspension: Check for leaks, damage, and proper damping.
  ✓ Electrical System: Check all connections for security and damage.
  ✓ License Plate and Registration: Ensure they are valid and securely attached.
  ✓ Test Drive: Take a short test drive to assess overall performance and identify any remaining issues.
`;


  const resetHandler = () => {
    // setTitle("");
    // setPosition("");
    // setfirst_name("");
    // setlast_name("");
    // setarea("");
    // setphone("");
    // setemail("");
    // setpassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // const postData = {
    //   title,
    //   position,
    //   first_name,
    //   last_name,
    //   area,
    //   phone,
    //   email,
    //   password,
    // };

    const data = JSON.parse(localStorage.getItem("userInfo")) || {};
    const token = data.token;

    // console.log("token:", token);
    fetch(`${apiUrl}/api/subusers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Data was successfully posted to the API");
        } else {
          console.log("Failed to post data to the API");
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });

    resetHandler();
  };

  useEffect(() => { }, []);

  return (
    <div className="overflow-auto"
      style={{
        height: "95vh"
      }}
    >
      <div className="offset-lg-3 col-lg-6">
        <div className="container">
          {/* <MainScreen title="Create User (Garage/pick-up) "> */}
          <Card>
            <Card.Title>
              {/* <h2>Create User: (Garage/pick-up)</h2> */}
              <h2 className="m-0 ms-3">Create New Vendor</h2>
            </Card.Title>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                {/* {error && <ErrorMessage variant="danger">{error}</ErrorMessage>} */}
                <Form.Group controlId="title" className="mb-3">
                  {/* <Form.Label>Title</Form.Label> */}
                  <Form.Label>Shop/ Garage Name</Form.Label>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the shop name"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Vendor type</Form.Label>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">2 Wheeler Servicing</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">4 Wheeler Servicing</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">Battery Shop</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">Towing Vendor</span>
                  </div>
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Owner name</Form.Label>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the owner name"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Shop/Office Address (Google Location)</Form.Label>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the shop/office address"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="exampleForm.SelectCustom" className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Select
                  // value={position}
                  // onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value="" defaultValue={"Select City"} disabled>Select City</option>
                    {city.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    // value={title}
                    placeholder="Enter the phone number"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Alternate Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    // value={title}
                    placeholder="Enter the alternate phone number"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Pin Code</Form.Label>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the pin code"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="exampleForm.SelectCustom" className="mb-3">
                  <Form.Label>Service Cover Area</Form.Label>
                  <Form.Select
                  // value={position}
                  // onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value="" defaultValue={"Select Service Cover Area"} disabled>Select Service Cover Area</option>
                    {serviceCoverArea.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustom" className="mb-3">
                  <Form.Label>Shop Time or Work Time</Form.Label>
                  <Form.Select
                  // value={position}
                  // onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value="" defaultValue={"Select Shop Time or Work Time"} disabled>Select Shop Time or Work Time</option>
                    {selectShopOrWorkTime.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustom" className="mb-3">
                  <Form.Label>Which type of 2-wheeler is serviced by garage?</Form.Label>
                  <Form.Select
                  // value={position}
                  // onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value="" defaultValue={"Select 2-wheeler type for service"} disabled>Select 2-wheeler type for service</option>
                    {selectTwoWheelerType.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>



                <Form.Group className="mb-4">
                  <Form.Label>
                    Service Charges for 2-wheeler without Oil
                  </Form.Label>
                  <div className="table-responsive">
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th className="text-secondary">Bike Type</th>
                          {twoWheelerWithoutOilColumns.map((col, index) => (
                            <th key={index}>{col}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {twoWheelerWithoutOilRows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <th>{row}</th>
                            {twoWheelerWithoutOilColumns.map((_, colIndex) => (
                              <td key={colIndex}>
                                <Form.Check type="checkbox" />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Form.Group>



                <Form.Group className="mb-4">
                  <Form.Label>
                    Service Charges for 2-wheeler with Oil
                  </Form.Label>
                  <div className="table-responsive">
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th className="text-secondary">Bike Type</th>
                          {twoWheelerWithOilColumns.map((col, index) => (
                            <th key={index}>{col}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {twoWheelerWithOilRows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <th>{row}</th>
                            {twoWheelerWithOilColumns.map((_, colIndex) => (
                              <td key={colIndex}>
                                <Form.Check type="checkbox" />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Form.Group>



                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Other serivce charges 2 wheeler</Form.Label>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the service charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="exampleForm.SelectCustom" className="mb-5">
                  <Form.Label>Which type of 4 wheeler is serviced by garage?</Form.Label>
                  <Form.Select
                  // value={position}
                  // onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value="" defaultValue={"Select 4-wheeler type for service"} disabled>Select 4-wheeler type for service</option>
                    {selectFourWheelerType.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>


                {/* Car Basic Servicing Charges */}
                <Form.Group controlId="title" className="mb-5">
                  <Form.Label>Car - Basic Servicing Charges</Form.Label>
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="0" className="p-0 border-1 bg-secondary-subtle">
                      <Accordion.Header className="accordion-header-no-padding">View car basic servicing charges description</Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "left",
                          }}
                        >
                          {carBasicServicingDescription}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Form.Control
                    type="text"
                    placeholder="Enter the car basic servicing charges value"
                  />
                </Form.Group>


                {/* Car Advanced Servicing Charges */}
                <Form.Group controlId="title" className="mb-5">
                  <Form.Label>Car - Advanced Servicing Charges</Form.Label>
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="0" className="p-0 border-1">
                      <Accordion.Header className="accordion-header-no-padding">View car advanced servicing charges description</Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "left",
                          }}
                        >
                          {carAdvancedServicingDescription}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Form.Control
                    type="text"
                    placeholder="Enter the car advanced servicing charges value"
                  />
                </Form.Group>


                {/* Car Pro Servicing Charges */}
                <Form.Group controlId="title" className="mb-5">
                  <Form.Label>Car - Pro Servicing Charges</Form.Label>
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="0" className="p-0 border-1">
                      <Accordion.Header className="accordion-header-no-padding">View car pro servicing charges description</Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "left",
                          }}
                        >
                          {carProServicingDescription}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Form.Control
                    type="text"
                    placeholder="Enter the car pro servicing charges value"
                  />
                </Form.Group>

                {/* Add EV description data further */}
                {/* Car EV Servicing Charges */}
                <Form.Group controlId="title" className="mb-5">
                  <Form.Label>Car - EV Servicing Charges</Form.Label>
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="0" className="p-0 border-1">
                      <Accordion.Header className="accordion-header-no-padding">View car ev servicing charges description</Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "left",
                          }}
                        >
                          {/* {carEVServicingDescription} */}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Form.Control
                    type="text"
                    placeholder="Enter the car pro servicing charges value"
                  />
                </Form.Group>



                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Battery Jump Charges</Form.Label>
                  <span className="text-secondary ms-2">(Day ₹ - Night ₹)</span>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the battery jump charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>



                <Form.Group className="mb-3">
                  <Form.Label>Towing Vehicle Available</Form.Label>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">2-Wheeler Flat Bed</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">4-Wheeler Flat Bed</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">Under Wheel Lift</span>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                    // value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="p-0">Zero Degree</span>
                  </div>
                </Form.Group>




                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>2-Wheeler Flat Bed Charges</Form.Label>
                  <span className="text-secondary ms-2">(Per KM)/ Local Charges</span>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the 2-wheeler flat bed charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>4-Wheeler Flat Bed Charges</Form.Label>
                  <span className="text-secondary ms-2">(Per KM)/ Local Charges</span>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the 4-wheeler flat bed charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Under Wheel Lift Charges</Form.Label>
                  <span className="text-secondary ms-2">(Per KM)/ Local Charges</span>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the under wheel lift charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Zero Degree Charges</Form.Label>
                  <span className="text-secondary ms-2">(Per KM)/ Local Charges</span>
                  <Form.Control
                    type="text"
                    // value={title}
                    placeholder="Enter the zero degree charges value"
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>


                {/* /////,,,,,,,,,,,,,,,  select options >>>   ,,,,,,,,,,,,,,,,,,,,,, */}

                {/* <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Select an Position:</Form.Label>
                  <Form.Select
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  > */}
                {/* <select value={position} onChange={SelectPosition}></select> */}
                {/* <option value="select">Select : </option>
                    <option value="garage">Service Provider</option>
                    <option value="garage">Garage</option>
                    <option value="pickup">Pick up</option>
                  </Form.Select>
                </Form.Group> */}

                {/* /////,,,,,,,,,,,,,,,,  <<<< select options   ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */}
                {/* <Form.Group controlId="content">
                  <Form.Label>first_name</Form.Label>
                  <Form.Control
                    type="content"
                    value={first_name}
                    placeholder="Enter the first_name"
                    onChange={(e) => setfirst_name(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group controlId="content">
                  <Form.Label>last_name</Form.Label>
                  <Form.Control
                    type="content"
                    value={last_name}
                    placeholder="Enter the last_name"
                    // rows={4}
                    onChange={(e) => setlast_name(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group controlId="content">
                  <Form.Label>area</Form.Label>
                  <Form.Control
                    type="content"
                    value={area}
                    placeholder="Enter the area"
                    // rows={4}
                    onChange={(e) => setarea(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group controlId="content">
                  <Form.Label>phone</Form.Label>
                  <Form.Control
                    type="content"
                    value={phone}
                    placeholder="Enter the phone"
                    // rows={4}
                    onChange={(e) => setphone(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group controlId="content">
                  <Form.Label>email</Form.Label>
                  <Form.Control
                    type="content"
                    value={email}
                    placeholder="Enter the email"
                    // rows={4}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group controlId="content">
                  <Form.Label>password</Form.Label>
                  <Form.Control
                    type="content"
                    value={password}
                    placeholder="Enter the password"
                    // rows={4}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </Form.Group> */}
                {/* {loading && <Loading size={50} />} */}
                <Button type="submit" variant="primary m-0 mt-2 me-3 p-2 ps-3 pe-3" >
                  Create SubUser
                </Button>
                <Button className="mx-2" onClick={resetHandler} variant="danger m-0 mt-2 me-3 p-2 ps-3 pe-3">
                  Reset Feilds
                </Button>
              </Form>
            </Card.Body>

            <Card.Footer className="text-muted">
              Creating on - {new Date().toLocaleDateString()}
            </Card.Footer>
          </Card>
          {/*  </MainScreen> */}
        </div>
      </div>
    </div>
  );
}

export default SubUserRegister;
