import React, { useEffect, useState } from "react";
import axios from "axios";

const MapVehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  // console.log("Vehicles::",vehicles);

  const [isLoading, setIsLoading] = useState(true);

  const handleGetVehicle = async () => {
    try {
      const responce = await axios.get(
        "https://gmi-server-1.onrender.com/api/vehicles/allvehicles"
      );
      setVehicles(responce.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetVehicle();
  }, []);

  const handleDeleteVehicle = async (id) => {
    // Ask for confirmation
    const confirmation = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmation) {
      try {
        await axios.delete(
          `https://gmi-server-1.onrender.com/api/vehicles/deletevehicle/${id}`
        );
        // Update the state to remove the deleted vehicle
        setVehicles((prevVehicles) =>
          prevVehicles.filter((vehicle) => vehicle._id !== id)
        );
      } catch (error) {
        console.error("Error deleting vehicle:", error);
      }
    }
  };

  if (isLoading) {
    return <div>Loading All vehicles data...</div>; // Display loading message while data is being fetched
  }

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      <button
        onClick={() => window.history.back()}
        style={{
          padding: "10px 20px",
          backgroundColor: "#FCC701",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          position: " fixed",
        }}
      >
        Go Back
      </button>

      {vehicles.map((vehicle) => (
        <div
          key={vehicle._id}
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            margin: "5px",
            padding: "5px",
            maxWidth: "200px",
            maxHeight: "300px",
            lineHeight: "3px",
            textAlign:'center'
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            {/* {vehicle.vehicle_image ? (
              <img
                src={vehicle.vehicle_image}
                alt={vehicle.make_model || "Vehicle Image"}
                style={{ maxWidth: "100px", marginBottom: "10px" }}
              />
            ) : null} */}
          </div>
          <h5>{vehicle.make_model}</h5>
          <p>
            <strong>Vehicle Type:</strong> {vehicle.vehicle_type}
          </p>
          <p>
            <strong>Variant:</strong> {vehicle.variant}
          </p>
          <p>
            <strong>Fuel Type:</strong> {vehicle.fuel_type}
          </p>
          <p>
            <strong>Bike CC:</strong> {vehicle.bike_cc}
          </p>
          <p>
            <strong>Car Type:</strong> {vehicle.car_type}
          </p>

          <button
            style={{
              width: "100%",
              height: "20px",
              margin: "auto",
              padding: "0 2%",
            }}
            onClick={() => handleDeleteVehicle(vehicle._id)}
          >
            Delete
          </button>
        </div>
      ))}
    </div>
  );
};

export default MapVehicles;
