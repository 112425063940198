import React, { useEffect, useState } from "react";
import axios from "axios";

function ImagesUpload() {
  const [image, setImage] = useState(null);
  const [allImage, setAllImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getImage();
  }, []);

  const submitImage = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("image", image);

    try {
      const result = await axios.post(
        "https://gmi-server-1.onrender.com/images/upload-image",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Image uploaded successfully!");
      getImage();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async (imageId) => {
    // Ask for confirmation
    const confirmation = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmation) {
      try {
        const result = await axios.delete(
          `https://gmi-server-1.onrender.com/images/${imageId}`
        );
        alert("Image deleted successfully!");
        getImage();
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };

  const onInputChange = (e) => {
    setImage(e.target.files[0]);
  };

  const getImage = async () => {
    try {
      const result = await axios.get(
        "https://gmi-server-1.onrender.com/images/get-image"
      );
      setAllImage(result.data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <div
      className="card overflow-auto"
      style={{
        height: "92vh",
      }}
    >
      <h2 className="m-0 mb-3">Upload Images:</h2>
      <form onSubmit={submitImage} className="d-flex gap-4">
        <input
          type="file"
          accept="image/*"
          onChange={onInputChange}
          className="form-control"
          style={{
            width: "40%",
          }}
        ></input>
        <button type="submit" className="btn btn-primary m-0">Submit</button>
      </form>
      {loading && <p>Loading...</p>}
      <div  className="d-flex flex-wrap gap-4">
      {allImage &&
        allImage.map((data) => (
              <div key={data._id} className="card border-0 d-flex flex-column text-center" style={{
                boxShadow:"0px 3px 4px 1px rgba(0,0,0,0.2)"
              }}>
              <img
                src={`https://gmi-server-1.onrender.com/uploadImages/${data.image}`}
                alt={data.image}
                height={250}
                width={250}
                style={{ flex: "0 0 auto", marginRight: "10px" }}
                />
              <h6 style={{ 
                // flex: "1"
              }}>{data.image}</h6>
              <button onClick={() => deleteImage(data._id)} className="btn btn-danger m-0">Delete</button>
            </div>
        ))}
    </div>
    </div>
  );
}

export default ImagesUpload;
